import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {HierarchyRectangularNode} from "d3";

export namespace taxonomy_editor {
    export type InputData = m_taxonomy.FullSerializer
    export type Values = m_taxonomy.Data
    export type VisualData = {
        labelVerticalPosition: number;
        chevronVerticalPosition: number;
        labelOpacity: number
        nodeOpacity: number
        nodeHeight: number
        overlap: string
    }
    export type _NodeData = {
        id: number
        dataLabel: string
        showLabel: string
        sources: number[]
        values: Values
        viz: VisualData
        target?: AnimationTarget,
    }
    export type NodeData = _NodeData & {
        children: NodeData[]
    }
    export type GraphNode = Omit<HierarchyRectangularNode<NodeData>, 'id'>
    // type NodeData = {
    //     labelVerticalPosition: number;
    //     labelOpacity: number
    //     nodeOpacity: number
    //     nodeHeight: number
    // }
    export type AnimationTarget = {
        x0: number
        x1: number
        y0: number
        y1: number
        viz: VisualData
    }
    // type Node = HierarchyRectangularNode<NodeData> & { target?: AnimationTarget } & ExtraProps;


    export type AddNodeData = {
        newId: number
        label: string
        description: string
        value: number
    }
    export type MergeNodeData = {
        sources: GraphNode[]
        destination: AddNodeData
    }

    export const _NO_VALUES: Values = {
        description: '',
        p__id__count: 0,
        p__spend__sum: 0,
        s__id__nunique: 0,
    }
    export const NO_VIZ_DATA: VisualData = {
        labelVerticalPosition: 0,
        chevronVerticalPosition: 0,
        labelOpacity: 1,
        nodeOpacity: 1,
        nodeHeight: 0,
        overlap: '',
    }

    export function NO_VALUES(description = ''): Values {
        return {
            ..._NO_VALUES,
            description,
        }
    }

    export function extractIntermediateValues(inputValues: Values): Values {
        // Extract intermediate values
        // TODO: Think more about whats happening here
        const partialValues = {}
        for (const k in inputValues) {
            const inputValue = inputValues[k];
            if (k === 'description' || k === 'category_id') {
                partialValues[k] = inputValue
                continue
            }
            if (typeof inputValue === 'number') {
                partialValues[k] = 0;
            } else {
                console.warn('Do not know how to extract', k, inputValues)
            }
        }
        return partialValues as any;
    }

    export function mergeValues(values: Values[], description: string): Values {
        const result: any = {}
        for (let v of values) {
            for (const k in v) {
                if (k === 'category_id') {
                    // Never merge category Ids but let the backend deduce new ones
                    continue
                }
                if (k === 'description') {
                    continue
                }
                const val = v[k];
                if (!(k in result)) {
                    result[k] = val;
                } else {
                    const prev = result[k];
                    if (typeof prev === 'number') {
                        result[k] += val
                        // TODO-PARKED: When merging nodes to sum of all suppliers is not correct
                    } else {
                        console.warn('Do not know how to merge', k, val)
                    }
                }
            }
        }

        // inv: result['category_id'] === undefined
        result['description'] = description
        return result
    }
}
