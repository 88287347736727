import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {TextField, Typography} from "@mui/material";
import {ApprovalDialog} from "../ApprovalDialog";
import {useHistory} from "react-router";
import {MithraHistoryState} from "../../../routing/routes";
import {runInAction} from "mobx";

export const TaxonomyApprovalAcceptDialog: React.FC = observer(() => {
    const history = useHistory<MithraHistoryState>();
    const {approvalStore} = useStores();

    return <ApprovalDialog
        className="approval-dialog"
        title="Should the request be accepted?"
        saveTitle={'Accept'}
        onSave={() => approvalStore.applyApproval(history)}
        canSave={!approvalStore.approvalLoader.busy}
        onClose={() => approvalStore.clickCancelApprovalDialog()}
        loading={approvalStore.approvalLoader.busy}
    >
        <Typography variant="body1" component="p">
            Mithra will be notified and run the AI algorithm again, this may take a few days to complete
        </Typography>
        <TextField
            fullWidth
            id="taxonomy-approval-feedback-notes"
            label="Notes"
            variant="outlined"
            value={approvalStore.approval?.feedback_notes || ''}
            onChange={e => runInAction(() => {
                if (approvalStore.approval) approvalStore.approval.feedback_notes = e.target.value;
            })}
            disabled={approvalStore.approvalLoader.busy}
        />
    </ApprovalDialog>
})
