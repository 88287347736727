import './IconStyles.scss'
import {CategoryOutlined, CompareArrowsOutlined} from "@mui/icons-material";
import React from "react";

export const SynergyCategoriesIcon = () => <>
    <div className="composite-icon-wrapper synergy-categories-icon hack-lighten-borders">
        <CategoryOutlined/>
        <CompareArrowsOutlined className="second-icon"/>
    </div>
</>
