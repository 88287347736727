import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {TaxonomyMapperStore} from "./TaxonomyMapperStore";
import {AxoisRequestManager} from "../../../stores/managers/RequestManager";
import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {from, Subject, tap} from "rxjs";
import {
    Data as MatchTreeData,
    MatchDataType,
    MatchUpdateData,
    Tree,
} from "../../../components/visualization/match-categories-tree-v2/MatchCategoriesTreeVisualization";
import {PipeManager2} from "../../../stores/managers/PipeManager";
import {
    CollapsibleIndentTreeBuilder
} from "../../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";

type CreateDeleteTask = {
    type: 'delete'
    id: number
} | ({ type: 'create', originalLink: MatchDataType } & m_taxonomy.CreateMaterializedCategoryMap2)

/**
 * The delegate that manages the actual mapping itself
 */
export class TaxonomyMapDelegate {
    readonly _catMapRequestManager = new AxoisRequestManager<{ srcMatTax: number, dstMatTax: number }, m_taxonomy.MaterializedCategoryMap[]>(
        ({srcMatTax, dstMatTax}) => from(this.api.listMatTaxonomyMapping(srcMatTax, dstMatTax)).pipe(
            tap(resp => this.setMapping(resp.data.map(m => ({
                selected: false,
                map: m,
                leftId: m.src_mat_category,
                rightId: m.dst_mat_category,
                beingCreated: false,
            }))))
        )
    );

    private readonly onCreateDeletePipeEmpty = new Subject<void>();
    readonly _createDeletePipe: PipeManager2<CreateDeleteTask>;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private taxonomyMapperStore: TaxonomyMapperStore,
    ) {
        this.onCreateDeletePipeEmpty.subscribe(() => {
            let matTaxIds = this.taxonomyMapperStore.matTaxIds;
            if (!matTaxIds.srcMatTax || !matTaxIds.dstMatTax) {
                console.error(`Cannot create node because of missing materialized taxonomies ${matTaxIds}`)
            }
            // TODO:
            //  To be safe this initializes the whole dataset
            //  So, the selection is lost when creating a new node
            this._catMapRequestManager.request(matTaxIds as any)
        })
        this._createDeletePipe = new PipeManager2<CreateDeleteTask>(
            t => t.type === 'delete'
                ? from(this.api.deleteMatTaxonomyMapping(t.id))
                :
                // timer(5000).pipe(concatMap(() =>
                from(this.api.createMatTaxonomyMapping2(t)).pipe(tap(() => runInAction(() => {
                    t.originalLink.beingCreated = false;
                }))),
            this.onCreateDeletePipeEmpty
        )
        makeAutoObservable(this)
    }

    public reset() {
        this.userVizPointData = undefined;
        this._catMapRequestManager.cleanup();
    }

    public register() {
        reaction(() => [
            this.taxonomyMapperStore.baseMatTaxonomy?.id,
            this.taxonomyMapperStore.targetMatTaxonomy?.id,
        ], ([srcMatTax, dstMatTax]) => {
            this.initialize(srcMatTax, dstMatTax);
        }, {fireImmediately: true})
    }

    public initialize(srcMatTax: number | undefined, dstMatTax: number | undefined) {
        if (srcMatTax && dstMatTax) {
            this.reset();
            this._catMapRequestManager.request({srcMatTax, dstMatTax});
        }
    }

    get vizTreeData(): MatchTreeData | undefined {
        if (!this.vizConnectionData) return undefined;
        const left = this.taxonomyMapperStore.baseTaxonomyHierarchy;
        const right = this.taxonomyMapperStore.targetTaxonomyHierarchy;
        if (!left || !right) return undefined;
        return {left, right, connections: this.vizConnectionData};
    }

    get initVizPointData(): Tree[] | undefined {
        if (!this.vizTreeData) return undefined;
        return this.vizTreeData.left.descendants().concat(this.vizTreeData.right.descendants())
    }

    get vizPointData(): Tree[] | undefined {
        if (this.userVizPointData) return this.userVizPointData;
        return this.initVizPointData;
    }

    userVizPointData?: Tree[]
    vizConnectionData?: MatchDataType[]

    setMapping(mapping: MatchDataType[]) {
        mapping.sort((a, b) => {
            if (b.map && a.map) {
                return m_taxonomy.sortKey(b.map).localeCompare(m_taxonomy.sortKey(a.map));
            }
            if (!b.map && !a.map) return 0;
            if (b.map) return -1;
            return 1;
        })
        this.vizConnectionData = mapping;
    }

    get connectionData(): MatchUpdateData | undefined {
        if (!this.vizPointData || !this.vizConnectionData) {
            return undefined;
        }
        return {points: this.vizPointData, connections: this.vizConnectionData};
    }

    createMapping(link: MatchDataType) {
        link.beingCreated = true;
        this._createDeletePipe.processNew({
            type: 'create',
            src_mat_category: link.leftId,
            dst_mat_category: link.rightId,
            originalLink: link,
        })
    }

    removeMapping(link: MatchDataType) {
        const id = link.map?.id;
        if (!id) {
            console.warn('Link was already deleted...', link)
            this._catMapRequestManager.request(this.taxonomyMapperStore.matTaxIds as any)
            return
        }
        this._createDeletePipe.processNew({
            type: 'delete',
            id,
        })
    }

    get baseMatchedCategoryIds(): Set<number> | undefined {
        if (!this.vizConnectionData) return undefined;
        return new Set(this.vizConnectionData.map(d => d.leftId));
    }

    get unMatchedCategories(): Tree[] | undefined {
        if (!this.taxonomyMapperStore.baseTaxonomyHierarchy) return undefined;
        const baseMatchedCategoryIds = this.baseMatchedCategoryIds;
        if (!baseMatchedCategoryIds) return undefined;

        // return this.taxonomyMapperStore.baseTaxonomyHierarchy
        //     .descendants()
        //     .filter(d => !baseMatchedCategoryIds.has(d.data.id));
        const result = [] as Tree[];
        this.taxonomyMapperStore.baseTaxonomyHierarchy
            .eachBefore((n, i) => {
                if (i === 0) return; // Do not show the root
                if (n.height !== 0) return; // Only consider leafs
                if (!baseMatchedCategoryIds.has(n.data.id)) {
                    result.push(n);
                }
            })
        return result;
    }

    getLeftUnmatchedNodeIds(): number[] {
        if (!this.taxonomyMapperStore.baseTaxonomyHierarchy || !this.connectionData) {
            return []
        }
        const allLeftIds = CollapsibleIndentTreeBuilder
            .getLeafs(this.taxonomyMapperStore.baseTaxonomyHierarchy as any)
            .map(n => n.data.id)
        // const allLeftIds = this.taxonomyMapperStore.baseTaxonomyHierarchy.leaves().map(d => d.data.id)
        // const allLeftIds = this.vizTreeData.left.leaves().map(d => d.data.id)
        // const allLeftIds = this.vizConnectionData.map(({leftId}) => leftId)
        const leftConnection = new Set(this.connectionData.connections.map(({leftId}) => leftId))
        return allLeftIds.filter(leftId => !leftConnection.has(leftId))
    }

    get busy() {
        return this._catMapRequestManager.busy || this._createDeletePipe.m.nInPipe > 0;
    }
}
