import {makeAutoObservable} from "mobx";

export class SearchManager {
    searchString = ''
    activeSearchString = ''

    constructor(private readonly onSearch: (string) => void) {
        makeAutoObservable(this)
    }

    resetSearch() {
        this.searchString = ''
        this.activeSearchString = ''
    }

    setSearch(search: string) {
        this.searchString = search;
    }

    clearSearch() {
        this.setSearch('')
        const changed = this.activeSearchString !== ''
        this.activeSearchString = '';
        if (changed) {
            this.onSearch(this.activeSearchString)
        }
        return changed;
    }

    doSearch() {
        this.activeSearchString = this.searchString.toLowerCase();
        this.onSearch(this.activeSearchString)
    }
}