import {D3Selection} from "./global";
import {select} from "d3";

export function appendLine<D>(s: D3Selection, x1: number, y1: number, x2: number, y2: number, className?: string) {
    const line = s.append('line')
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
    if (className) {
        line.classed(className, true);
    }
    return line;
}

export function wrapText(maxWidth, getText: (n: any) => string) {
    return function _wrapText(this: any) {
        // Taken from: https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
        const self = select(this)

        const data = self.data() as any[];
        if (data.length !== 1) {
            return
        }
        let text = getText(data[0])
        self.text(text)

        let textLength = self.node().getComputedTextLength()
        // console.log('textLength', textLength, '->', maxWidth)
        while (textLength > maxWidth && text.length > 0) {
            text = text.slice(0, -1);
            self.text(text + '...');
            textLength = self.node().getComputedTextLength();
        }
    }
}


export function wrapText2<T>(maxWidth, getTextParts: (n: T) => [string, string], filler) {
    return function _wrapText(this: any) {
        // Taken from: https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
        const self = select(this)

        const data = self.data() as any[];
        if (data.length !== 1) {
            return
        }
        let [t1, t2] = getTextParts(data[0])
        self.text(t1 + t2)

        let textLength = self.node().getComputedTextLength()
        // console.log('textLength', textLength, '->', maxWidth)
        while (textLength > maxWidth && t1.length > 0) {
            t1 = t1.slice(0, -1);
            self.text(t1 + filler + t2);
            textLength = self.node().getComputedTextLength();
        }
    }
}
