import {observer} from "mobx-react-lite";
import {NavigateNext} from "@mui/icons-material";
import {Breadcrumbs} from "@mui/material";
import {useStores} from "../../../stores";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";


export const TaxonomyEditorBreadcrumbs = observer(() => {
    const { taxonomyEditorStore } = useStores();
    return <>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            {taxonomyEditorStore.getFocusedAncestors(taxonomyEditorStore.focus)?.map((category) => {
                const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
                return <div key={category}>{categoryLabel}</div>
            })}
        </Breadcrumbs>
    </>;
})