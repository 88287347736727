import {Typography} from "@mui/material";
import React from "react";

export const PurchasePriceVarianceHelp = () => <>
    <Typography color="textSecondary" gutterBottom>
        KOI: Purchase Price Variance
    </Typography>
    {/*<Typography variant="h5">*/}
    {/*    Buy from less*/}
    {/*</Typography>*/}
    <Typography component="p">
        The Product or Purchase Price Variance shows
        the difference between the average and the minimum price of a product.
    </Typography>
</>;
