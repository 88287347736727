import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const SynergyV1Menu: React.FC = () => {
    const {p} = useStores();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    const hasId2 = m.params['id2'] !== undefined;
    const homeLink = lookupHomePath(m.path);

    // const existsHome = homeLink !== null;
    const isHome = m.path === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: generatePath(
                hasId2 && hasId1
                    ? (
                        routes.job_synergy_v1_2
                    )
                    : hasId1
                        ? routes.job_synergy_v1_1
                        : routes.job_synergy_v1_0,
                m.params
            ),
            active: (
                m.path === routes.job_synergy_v1_0
                || m.path === routes.job_synergy_v1_1
                || m.path === routes.job_synergy_v1_2
            ),
            className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.job_synergy_v1_opportunities, m.params)
                : routes.job_synergy_v1_0,
            active: !isHome && m.path === routes.job_synergy_v1_opportunities,
            isTrial: p.p.tease_main_menu,
            className: 'menu-item-2nd-last',
        },
    ]
    return <MenuItems className="synergy-v1-menu" items={menu}/>
}
