import {observer} from "mobx-react-lite";
import {NavigateNext} from "@mui/icons-material";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import React, {MouseEventHandler} from "react";
import {useStores} from "../../../stores";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";


export const SpendConcentrationBreadcrumbs = observer(() => {
    const {spendConcentrationStore} = useStores();
    return <>
        {/*<p>selectedLevel={'' + spendConcentrationStore.selectedLevel}</p>*/}
        {/*<p>selectedCategory={'' + spendConcentrationStore.selectedCategory}</p>*/}
        <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
        {spendConcentrationStore.selectedCategory.map((category, index) => {
            const level = index + 1;
            const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
            const onClick: MouseEventHandler = e => {
                e.preventDefault();
                spendConcentrationStore.navigateToLevel(level - 1);
            }
            return <Link onClick={onClick} key={category} href="">{categoryLabel}</Link>
        })}
        {spendConcentrationStore.canSelectLevelDeeper &&
            <Typography color="textPrimary" className="">Select a L{spendConcentrationStore.selectedLevel + 1}</Typography>
        }
    </Breadcrumbs>
        </>;
})
