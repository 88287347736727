import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {CommonSuppliersDelegate} from "./synergy/CommonSuppliersDelegate";
import {CommonCategoriesDelegate} from "./synergy/CommonCategoriesDelegate";

export class SynergyStore {
    requestedSynergyId?: number = this.profileStore.p.hardcodedSynergyId || 1;

    readonly datasetNameLeft = this.profileStore.p.hardcodedDatasetNameLeft || 'None'
    readonly datasetNameRight = this.profileStore.p.hardcodedDatasetNameRight || 'None'
    readonly commonSuppliersDelegate = new CommonSuppliersDelegate(this, this.bagStore, this.matApi, this.profileStore)
    readonly commonCategoriesDelegate = new CommonCategoriesDelegate(this, this.bagStore, this.matApi, this.profileStore)

    // noinspection JSUnusedLocalSymbols
    constructor(
        private bagStore: BagStore,
        private profileStore: ProfileStore,
        private matApi: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }
}
