import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, TaxonomyRouteMatch} from "../../../routing/routes";
import {getAppEnvironment} from "../../../routing/routing.utils";
import {SelectDataset} from "../../../components/main/header/SelectDataset";
import React from "react";
import {MainMenuSynergyComponent} from "./MainMenuSynergy";
import {MultiDropdownMenu} from "./MultiDropdownMenu";
import {MainMenuSingleComponent} from "./MainMenuSingle";
import {observer} from "mobx-react-lite";

export const MultiHeader = observer(() => {
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const appEnv = getAppEnvironment(m.path);
    // console.debug('Re-rendering MultiHeader', appEnv)
    return <>
        {(appEnv === 'synergy_v3' || appEnv === 'synergy_merge') && <MainMenuSynergyComponent/>}
        {(appEnv === 'single_cleansing' || appEnv === 'single_analytics') && <MainMenuSingleComponent/>}
        <div style={{flexGrow: 1}}/>
        <SelectDataset/>
        <MultiDropdownMenu/>
    </>;
})
