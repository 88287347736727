import React from "react";
import {generatePath} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../routing/routes";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const MainMenuSingleComponent: React.FC = () => {
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    // const hasId2 = m.params['id2'] !== undefined;
    let menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.single_data_manage, m.params) : routes.single_data_home,
            active: (
                m.path === routes.single_data_home
                || m.path === routes.single_data_manage
            ),
            // className: '',
        },
        {
            label: 'Cleanup',
            link: hasId1 ? generatePath(routes.single_cleansing_dashboard, m.params) : routes.single_data_home,
            active: (
                m.path === routes.single_cleansing_dashboard
                || m.path === routes.single_cleansing_sup_nor
                || m.path === routes.single_cleansing_cat_welcome
                || m.path === routes.single_cleansing_cat_busy
                || m.path === routes.single_cleansing_cat_review
                || m.path === routes.single_cleansing_cat_result
                || m.path === routes.single_cleansing_taxonomy_builder
                || m.path === routes.single_cleansing_taxonomy_builder_id
            ),
            // className: '',
        },
        {
            label: 'Analytics',
            link: hasId1 ? generatePath(routes.single_analytics_dashboard, m.params) : routes.single_data_home,
            active: (
                m.path === routes.single_analytics_dashboard
                || m.path === routes.single_analytics_koi_ss
                || m.path === routes.single_analytics_koi_kdf
                || m.path === routes.single_analytics_koi_ppv
                || m.path === routes.single_analytics_koi_sc
                || m.path === routes.single_looker_preview
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.single_data_opportunities_id, m.params) : routes.single_data_opportunities,
            active: (
                m.path === routes.single_data_opportunities
                || m.path === routes.single_data_opportunities_id
            ),
            // className: 'menu-item-2nd-last',
        },
        {
            label: 'Report',
            link: hasId1 ? generatePath(routes.single_data_report, m.params) : routes.single_data_home,
            active: (
                m.path === routes.single_data_report
            ),
            // className: 'menu-item-last',
        },
    ];
    return <MenuItems className="single-main-menu" items={menu}/>
}
