import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import React from "react";
import {useStores} from "../../../stores";

export const CategorizationReviewKeyValues = observer(() => {
    const {categorizationStore} = useStores();

    const keyValues = categorizationStore.data.summaryResultKeyValues;

    return <Grid container justifyContent="center">
        {/* 3-6-3 */}
        <Grid className="key-value-container narrow-margin text-center font-bigger"
              item xs={6}
              container justifyContent="space-between">
            <Grid item>
                <Typography variant="caption">
                    Categorized
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value">
                    {keyValues
                        ? <CurrencyComponent
                            v={keyValues.after.classified_spend}
                            nDigits={3}
                            abbreviation={keyValues.abbreviation}
                            hideAlmostZero={true}
                        />
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    Re-categorized
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value">
                    {keyValues
                        ? <CurrencyComponent
                            v={keyValues.after.reclassified_spend}
                            nDigits={3}
                            abbreviation={keyValues.abbreviation}
                            hideAlmostZero={true}
                        />
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    Uncategorized
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value value-warning">
                    {keyValues
                        ? <CurrencyComponent
                            v={keyValues.after.unclassified_spend}
                            nDigits={3}
                            abbreviation={keyValues.abbreviation}
                            hideAlmostZero={true}
                        />
                        : <>&nbsp;</>}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
});