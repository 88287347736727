import './IconStyles.scss'
import {CompareArrowsOutlined, PeopleOutline} from "@mui/icons-material";
import React from "react";

export const SynergySuppliersIcon = () => <>
    <div className="composite-icon-wrapper synergy-supplier-icon">
        <PeopleOutline/>
        <CompareArrowsOutlined className="second-icon hack-lighten-borders"/>
    </div>
</>
