import React from "react";
import {Alert, Button, CircularProgress, Grid, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";

export const APPROVAL_DIALOG_TITLE_ID = 'approval-dialog-title';

export const ApprovalDialog: React.FC<{
    className?: string
    title: string

    saveTitle: string
    onSave: () => void
    canSave: boolean

    loading?: boolean

    onClose: () => void

    error?: string
}> = ({className, title, saveTitle, onSave, canSave, loading, onClose, error, children}) =>
    <Grid container direction="column" className={"edit-model-component" + (className ? ' ' + className : '')}>
        <Grid item container>
            <Grid item xs={8}
                  container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={3}>
                <Grid item>
                    <Typography id={APPROVAL_DIALOG_TITLE_ID} variant="h5">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    {children}
                </Grid>
            </Grid>
            <Grid item xs={2}/>
            <Grid item xs={2} alignItems="stretch"
                  spacing={3}
                  container direction="column" justifyContent="space-between">
                <Grid item>
                    <Button
                        fullWidth
                        className="btn-red"
                        startIcon={<Close/>}
                        onClick={onClose}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={!canSave}
                        fullWidth
                        startIcon={loading === true ? <CircularProgress size={14}/> : <Check/>}
                        onClick={onSave}
                        variant="outlined"
                    >
                        {saveTitle}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        {error && <Grid item className="mt-3">
            <Alert severity="error">{error}</Alert>
        </Grid>}
    </Grid>

