import {makeAutoObservable} from "mobx";
import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {SynergyStore} from "../SynergyStore";

/**
 * The synergy store for common categories
 */
export class CommonCategoriesDelegate {
    readonly numberTotalCategoriesLeft: number = this.profileStore.p.hardcodedNumberTotalCategoriesLeft || -1;
    readonly numberTotalCategoriesRight: number = this.profileStore.p.hardcodedNumberTotalCategoriesRight || -1;
    readonly numberTotalCommonCategories: number = this.profileStore.p.hardcodedNumberTotalCommonCategories || -1;
    readonly totalSpendCommonCategories: number = this.profileStore.p.hardcodedTotalSpendCommonCategories || -1;
    readonly numberCommonCategoriesLeft: number = this.profileStore.p.hardcodedNumberCommonCategoriesLeft || -1;
    readonly numberCommonCategoriesRight: number = this.profileStore.p.hardcodedNumberCommonCategoriesRight || -1;
    readonly totalCategoriesSpendLeft: number = this.profileStore.p.hardcodedTotalCategoriesSpendLeft || -1;
    readonly totalCategoriesSpendRight: number = this.profileStore.p.hardcodedTotalCategoriesSpendRight || -1;
    readonly commonCategoriesSpendLeft: number = this.profileStore.p.hardcodedCommonCategoriesSpendLeft || -1;
    readonly commonCategoriesSpendRight: number = this.profileStore.p.hardcodedCommonCategoriesSpendRight || -1;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private synergyStore: SynergyStore,
        // private approvalStore: ApprovalStore,
        // private auth: AuthStore,
        private bagStore: BagStore,
        private matApi: MithraMaterializedApi,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }


}
