export function getParetoFunction(a = 0.8, max = 1): (v: number) => number {
    const X = Math.log(1 - a) / Math.log(1 / (2 - a));
    const MAX = max;
    return v => {
        const r = 1 - Math.pow((1 / ((v) + 1)), X);
        console.log(`p[a=${a}, max=${MAX}] (${v}) = ${r}`);
        return r * MAX;
    };
}

// const f = getParetoFunction()
// console.log('getParetoFunction(80) .2 => ' + f(0.2))
// console.log('getParetoFunction(80) .0 => ' + f(0.0))
// console.log('getParetoFunction(80) .8 => ' + f(0.8))
