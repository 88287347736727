import './SynergyCommonSupplierComparePage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {useStores} from "../../../stores";
import {SynergyCommonSupplierReviewMainTable} from "./SynergyCommonSupplierReviewMainTable";
import {SynergyCommonSupplierToolbar} from "./SynergyCommonSupplierToolbar";
import {environment} from "../../../env";

export const SynergyCommonSupplierCompare: React.FC = observer(() => {
    const {synergyStore} = useStores();

    useEffect(() => {
        synergyStore.commonSuppliersDelegate.init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Grid component="section"
                 className="page-item step synergy-common-supplier-step"
                 container justifyContent="center">

        {environment.isTest && <Grid item xs={12}><h1>SynergyCommonSupplierCompare</h1></Grid>}
        <Grid item xl={8} lg={10} xs={12}>
            <SynergyCommonSupplierToolbar isParent={false}/>

            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    {/*{keyValues?.abbreviation*/}
                    {/*    && <CurrencyAbbreviationContext.Provider value={keyValues.abbreviation}>*/}
                    {/*        <SynergyCommonSupplierReviewMainTable/>*/}
                    {/*    </CurrencyAbbreviationContext.Provider>*/}
                    {/*}*/}
                    <SynergyCommonSupplierReviewMainTable/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
})
