import './AcceptRejectButtons.scss';
import React from "react";
import {CancelOutlined, CheckCircleOutline, People} from '@mui/icons-material';

type Props = {
    isAccept: boolean,
    isReject: boolean,
    onAccept?: () => void
    onReject?: () => void
    disabled?: boolean
    byOther?: boolean
}
export const AcceptRejectButtons: React.FC<Props> = ({isAccept, isReject, onAccept, onReject, disabled, byOther}) => {
    return <div className="accept-reject-buttons">
        {byOther && <People/>}
        <CancelOutlined
            className={
                'reject'
                + (isReject ? ' active' : ' inactive')
                + (onReject ? ' clickable' : '')
                + (disabled ? ' disabled' : '')
            }
            onClick={onReject}
        />
        <CheckCircleOutline
            className={
                'accept'
                + (isAccept ? ' active' : ' inactive')
                + (onAccept ? ' clickable' : '')
                + (disabled ? ' disabled' : '')
            }
            onClick={onAccept}
        />
    </div>;
}
