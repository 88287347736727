import React from 'react';
import {styled} from '@mui/material/styles';
import {Bag} from "../../services/classes/Bag";
import {FormControl, FormHelperText, Grid, Input, InputLabel} from "@mui/material";
import {Property} from "csstype";

const PREFIX = 'DataSetViewComponent';

const classes = {
    formControl: `${PREFIX}-formControl`,
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        margin: theme.spacing(1),
        minWidth: 300,
        minHeight: '4em',
    }
}));

type Props = {
    id?: string,
    className?: string,
    bag?: Bag | null,
    label: string,
    fullWidth?: boolean,
    centered?: boolean,
    error?: string,
}
export const DataSetViewComponent: React.FC<Props> = (
    {
        id,
        className,
        bag,
        label,
        fullWidth,
        centered,
        error,
    }) => {
    const justifyContent: Property.JustifyContent | undefined = centered ? 'center' : undefined;
    const labelId = id + "selected-bag-label";
    const fieldId = id + "selected-bag";
    className = "form-element dataset-view-component" + (className ? ` ${className}` : '');
    return <StyledGrid container className={className} justifyContent={justifyContent}>
        <Grid item xs={fullWidth ? 12 : undefined}>
            <FormControl
                className={classes.formControl}
                variant="standard" // Only needed for Input instead of OutlinedInput
                fullWidth={fullWidth}
            >
                <InputLabel id={labelId} htmlFor={fieldId}>
                    {label}
                </InputLabel>
                {/*<OutlinedInput*/}
                <Input
                    // label={label} // Only needed for OutlinedInput
                    id={fieldId}
                    aria-describedby={labelId}
                    className="select-bag-field"
                    readOnly={true}
                    fullWidth={fullWidth}
                    error={Boolean(error)}
                    value={bag ? bag.name : bag === null ? 'No bag exists' : 'Loading...'}
                    disabled={!bag}
                    // disabled={true}
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Grid>
    </StyledGrid>
}
