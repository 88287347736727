import {Options} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";

export const cmpVizOptionsLeft: Partial<Options> = {
    alignRight: true,
    height: 300,
    width: 600,
    barPortion: .3,
    flowStrokeWidth: 0,
};

export const cmpVizOptionsRight: Partial<Options> = {
    alignRight: false,
    height: 300,
    width: 600,
    barPortion: .3,
    flowStrokeWidth: 0,
};

export const cmpVizTabletOptionsLeft: Partial<Options> = {
    alignRight: true,
    height: 250,
    width: 300,
    barPortion: .3,
    flowStrokeWidth: 0,
    hoverVisible: true,
    margin: {
        top: 37,
        bottom: 37,
        left: 1,
        right: 10,
    },
};

export const cmpVizTabletOptionsRight: Partial<Options> = {
    alignRight: false,
    height: 250,
    width: 300,
    barPortion: .3,
    flowStrokeWidth: 0,
    hoverVisible: true,
    margin: {
        top: 37,
        bottom: 37,
        left: 10,
        right: 1,
    },
};

// Condensed
export const cmpVizCondensedOptionsLeft: Partial<Options> = {
    alignRight: true,
    height: 250,
    width: 600,
    barPortion: .35,
    flowStrokeWidth: 0,
};
export const cmpVizCondensedOptionsRight: Partial<Options> = {
    alignRight: false,
    height: 250,
    width: 600,
    barPortion: .35,
    flowStrokeWidth: 0,
};

// Tablet Condensed
export const cmpVizTabletCondensedOptionsLeft: Partial<Options> = {
    alignRight: true,
    height: 250,
    width: 300,
    barPortion: .35,
    flowStrokeWidth: 0,
    hoverVisible: true,
    margin: {
        top: 37,
        bottom: 37,
        left: 1,
        right: 10,
    },
};
export const cmpVizTabletCondensedOptionsRight: Partial<Options> = {
    alignRight: false,
    height: 250,
    width: 300,
    barPortion: .35,
    flowStrokeWidth: 0,
    hoverVisible: true,
    margin: {
        top: 37,
        bottom: 37,
        left: 10,
        right: 1,
    },
};
