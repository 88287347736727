import {Grid, TextField, Typography} from "@mui/material";
import React from "react";
import {GenericModel} from "../../components/modal/GenericModal";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {runInAction} from "mobx";

// const SUPPLIER_OPPORTUNITY_TYPES = [
//     {label: '', value: ''},
// ]

type Props = {
    isOpen: boolean
    setOpen: (open: boolean) => void
}
export const SupplierConsolidationOpportunityModal: React.FC<Props> = observer(({isOpen, setOpen}) => {
    const {spendConcentrationStore, opportunityStore} = useStores();

    let opportunityId;


    return <GenericModel
        title="Create Opportunity"
        saveTitle={opportunityStore.isEdit ? 'Ok' : 'Create'}
        isOpen={isOpen}
        onSave={() => runInAction(() => {
            if (!opportunityStore.isEdit) {
                opportunityStore.createOpportunity({
                    id: opportunityId,
                    title: opportunityStore.editingScOpportunity.title,
                    checked: false,
                    data: {
                        type: 'supplier_consolidation',
                        otherSupplierName: opportunityStore.editScData.otherSupplierName,
                        opportunitySize: opportunityStore.editScData.opportunitySize,
                        notes: opportunityStore.editScData.notes,
                    }
                });
                }
                setOpen(false);
            })}
            onCancel={() => setOpen(false)}
            className="category-synergy-modal"
            titleId="category-synergy-modal-title"
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Opportunity name"
                        variant="outlined"
                        value={opportunityStore.editingScOpportunity.title}
                        onChange={e => runInAction(() => opportunityStore.editingScOpportunity.title = e.target.value)}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography className="value-title">Supplier</Typography>
                    <Typography className="value">{spendConcentrationStore.selectedSupplierSpec?.label}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Replacing supplier"
                        variant="outlined"
                        value={opportunityStore.editScData.otherSupplierName}
                        onChange={e => runInAction(() => opportunityStore.editScData.otherSupplierName = e.target.value)}
                    />
                    <TextField
                        className="mt-3"
                        type="number"
                        label="Oppruntiy size"
                        variant="outlined"
                        value={opportunityStore.editScData.opportunitySize}
                        onChange={e => runInAction(() => opportunityStore.editScData.opportunitySize = Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography className="value-title">Supplier spend</Typography>
                    <Typography className="value">
                        <CurrencyComponent v={spendConcentrationStore.selectedSupplierSpec?.totalSpend || 0}/>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className="value-title">Category</Typography>
                    <Typography className="value">{spendConcentrationStore.selectedCategory.join(', ')}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        className="mt-3"
                        fullWidth
                        multiline
                        label="Notes"
                        variant="outlined"
                        value={opportunityStore.editScData.notes}
                        placeholder="Add some notes here (optional)"
                        onChange={e => runInAction(() => opportunityStore.editScData.notes = e.target.value)}
                    />
                </Grid>
            </Grid>
        </GenericModel>
    }
)
