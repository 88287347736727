import React from "react";
import {styled} from '@mui/material/styles';
import {
    Checkbox,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import {CurrencyComponent} from "../currency-component/CurrencyComponent";
import './DataTable.scss';
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@mui/icons-material";
import {TablePaginationActionsProps} from "@mui/material/TablePagination/TablePaginationActions";

const PREFIX = 'TablePaginationActions';

const classes = {
    root: `${PREFIX}-root`
};

const StyledTablePaginationActions = styled('div')(({theme}) => ({
    [`& .${classes.root}`]: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    }
}));

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({count, page, rowsPerPage, onPageChange}) => {
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return <StyledTablePaginationActions>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            <FirstPage/>
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            <KeyboardArrowLeft/>
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            <KeyboardArrowRight/>
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            <LastPage/>
        </IconButton>
    </StyledTablePaginationActions>
}

type DataCategory = 'currency' | 'percentage' | 'decimal-1' | 'currency|str' | 'checkbox';
export type DataColumn = {
    dataIndex: string;
    headerText: string;
    help?: string;
    dataCategory?: DataCategory;
    ltr?: boolean;
}

type Props = {
    tableColumns: DataColumn[];
    data?: any[];
    emptyCaption?: string;
    className?: string;
    pageSize?: number;
    pageSizeOptions?: Array<number | { value: number; label: string }>;
    onClickData?: (d: any) => void;
    onSelection?: {
        dataKeySelector: (d: any, i: number) => string,
        onSelectData: (d: any) => void,
    };
    checkboxDisabled?: boolean;
    onClickCheckbox?: (d: any, checked: boolean) => void;
    extraNarrow?: boolean;
    fluidHeight?: boolean;
    noContainer?: boolean;
    loading?: boolean;
    enableLoading?: boolean;
};
export const DataTable: React.FC<Props> = (
    {
        pageSize,
        pageSizeOptions,
        tableColumns,
        data,
        emptyCaption,
        className,
        onClickData,
        onSelection,
        checkboxDisabled,
        onClickCheckbox,
        extraNarrow,
        fluidHeight,
        noContainer,
        loading,
        enableLoading,
    }
) => {
    const dataKeySelector = onSelection ? onSelection.dataKeySelector : (_, di) => `${di}`;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize || 12);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState(pageSizeOptions || [
        5, 12, 25,
        // {label: 'All', value: -1},
    ]);
    const [selectedKey, setSelectedKey] = React.useState<undefined | string>(undefined);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (data?.length || 0) - page * rowsPerPage);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const containerComponent = noContainer ? 'div' : Paper;

    let UGLY_CAPATION_HACK = 53
    if (className?.includes('x-design')) {
        UGLY_CAPATION_HACK = 29
    }

    return <TableContainer component={containerComponent} className={
        'data-table'
        + (className ? (' ' + className) : '')
        + (onSelection ? ' selectable' : '')
        + (loading ? ' loading' : '')
    }>
        <Table>
            <TableHead>
                <TableRow>
                    {tableColumns.map(col => {
                        const align = col.ltr ? 'left' : 'right';
                        const colClassName = 'col-' + col.dataIndex
                            + (col.help ? ' col-help' : '')
                        return <TableCell
                            key={col.dataIndex}
                            className={colClassName}
                            align={align}
                            title={col.help} // Tooltip
                        >
                            {col.headerText}
                        </TableCell>
                    })}
                </TableRow>
                {enableLoading && <div className={`progress ${loading ? ' loading' : ''}`}>
                    <LinearProgress variant={"indeterminate"}/>
                </div>}
            </TableHead>
            {data === undefined
                ? <caption
                    style={{height: fluidHeight ? '' : UGLY_CAPATION_HACK * emptyRows}}>{emptyCaption || ''}</caption>
                : <>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                        ).map((d, di) => {
                            const dataKey = dataKeySelector(d, di);
                            const rowProps = {key: dataKey};
                            if (onClickData) {
                                rowProps['hover'] = true;
                                rowProps['onClick'] = (() => onClickData(d));
                            } else if (onSelection) {
                                rowProps['hover'] = true;
                                rowProps['onClick'] = (() => {
                                    if (selectedKey === dataKey) {
                                        setSelectedKey(undefined);
                                        onSelection.onSelectData(undefined);
                                    } else {
                                        setSelectedKey(dataKey);
                                        onSelection.onSelectData(d);
                                    }
                                });
                                rowProps['className'] = dataKey === selectedKey ? 'selected' : '';
                            }
                            return <TableRow {...rowProps}>
                                {tableColumns.map((col, index) => {
                                    const val = getValDataTable(col, d)
                                    // const key = d.description + '_' + col.dataIndex;
                                    const key = di + '_' + col.dataIndex;
                                    const className = 'col-' + col.dataIndex;
                                    // const key = '';
                                    const component = index === 0 ? 'th' : undefined;
                                    const scope = index === 0 ? 'row' : undefined;
                                    const align = col.ltr ? 'left' : 'right';
                                    if (col.dataCategory === 'checkbox' && typeof val === 'boolean') {
                                        return <TableCell className={className} key={key} component={component}
                                                          scope={scope} align={align}>
                                            <Checkbox
                                                checked={val}
                                                onClick={() => {
                                                    if (onClickCheckbox) onClickCheckbox(d, val);
                                                }}
                                                disabled={checkboxDisabled}/>
                                        </TableCell>
                                        }
                                        return <TableCell className={className} key={key} component={component}
                                                          scope={scope} align={align}>
                                            {val}
                                        </TableCell>
                                    })}
                                </TableRow>;
                            }
                        )}
                        {emptyRows > 0 && (!fluidHeight) && (
                            <TableRow style={{height: UGLY_CAPATION_HACK * emptyRows}}>
                                <TableCell colSpan={tableColumns.length}/>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                colSpan={tableColumns.length}
                                count={data?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </>
            }
        </Table>
    </TableContainer>
};

export function getValDataTable(col: DataColumn, d: any) {
    const spec = col.dataCategory;
    const val = d[col.dataIndex];
    if (!spec) return val;
    if (spec.endsWith('|str')) {
        if (typeof val === 'string') return val;
    }
    if (spec === 'checkbox') {
        return val;
    }
    if (spec.startsWith('currency')) {
        return <CurrencyComponent v={+val}/>;
    }
    if (spec.startsWith('percentage')) {
        return `${Math.round(+val * 100)}%`;
    }
    if (spec.startsWith('decimal-1')) {
        return `${Math.round(+val * 10) / 10}`;
    }
    return String(val);
}
