import {Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useState} from "react";
import {Tune} from "@mui/icons-material";
import {hardcoded_dpw} from "../hardcoded/hardcoded_dpw";
import {SynergySupplierMainRow} from "./SynergySupplierTableRow";
import {SynergySupplierFilterModal} from "./SynergySupplierFilterModal";

export const SynergySupplierTable = () => {

    const [isFilter, setFilter] = useState(false);

    return <>
        <SynergySupplierFilterModal isOpen={isFilter} setOpen={setFilter}/>
        <Grid container justifyContent="flex-end">
            <Grid item>
                <Button startIcon={<Tune/>} variant="outlined" onClick={() => setFilter(true)}>
                    Filter
                </Button>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <TableContainer className="mithra-table x-design synergy-cmp-supplier-table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="col-dropdown"/>
                                <TableCell className="col-s_name">
                                    Supplier name
                                </TableCell>
                                <TableCell className="col-s_type">
                                    Supplier Type
                                </TableCell>
                                <TableCell className="col-s_spend_combined">
                                    Combined
                                </TableCell>
                                <TableCell className="col-s_spend_master_ds">
                                    Master dataset
                                </TableCell>
                                <TableCell className="col-s_spend_cmp_ds">
                                    Compare dataset
                                </TableCell>
                                <TableCell className="col-s_categories">
                                    Categories
                                </TableCell>
                                <TableCell className="col-opportunity" align="right">
                                    Opportunity
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hardcoded_dpw.COMMON_SUPPLIER_TABLE_DATA.map((row, i) =>
                                <SynergySupplierMainRow key={i} data={row}/>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </>
}
