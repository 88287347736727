import React from "react";
import {Grid, Typography} from "@mui/material";

type RowProps = {
    className: string
    title?: string
    subtitle?: React.ReactNode
    values?: {
        title: string
        subtitle: React.ReactNode
    }[]
}
export const DashboardRow: React.FC<RowProps> = ({className, title, subtitle, values, children}) => {
    if (values !== undefined && (title !== undefined || subtitle !== undefined)) {
        throw new Error('DashboardRow: Over specified, either specify values or title/subtitle not both');
    }
    if (values === undefined) {
        values = [{
            title: title || '',
            subtitle: subtitle || ''
        }]
    }
    return <Grid item lg={3} xs={4} className={`dashboard-row ${className}`}>
        {values.map(({title, subtitle}, i) =>
            <Grid key={i} container direction="column" alignItems="center">
                <Grid item className="dashboard-row-title">
                    <Typography variant="h2" component="h2">
                        {title}
                    </Typography>
                </Grid>
                <Grid item className="dashboard-row-subtitle">
                    <Typography variant="subtitle2" component="h2">
                        {subtitle}
                    </Typography>
                </Grid>
            </Grid>
        )}
        <Grid container justifyContent="center">
            <Grid item sm={11} xs={12} className="dashboard-row-content">
                {children}
            </Grid>
        </Grid>
    </Grid>;
}
