import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {EditorModalComponent} from "./EditorModalComponent";
import {TextField} from "@mui/material";

export const MergeCategoryModal: React.FC = observer(() => {
    const {taxonomyEditorStore} = useStores();
    const onSave = () => taxonomyEditorStore.saveMergeCategory();
    return <EditorModalComponent
        titleId={"taxonomy-editor-modal-title"}
        title="Merge categories"
        canSave={taxonomyEditorStore.canMergeCategories}
        onSave={onSave}
        onClose={() => taxonomyEditorStore.setMergeCategoryMode(false)}
    >
        <TextField
            autoFocus
            fullWidth
            style={{marginBottom: 30}}
            id="taxonomy-editor-modal-new-category-label"
            label="New category name"
            error={Boolean(taxonomyEditorStore.mergeCategoryNameError)}
            helperText={taxonomyEditorStore.mergeCategoryNameError}
            variant="outlined"
            value={taxonomyEditorStore.mergeCategoryName}
            onChange={e => taxonomyEditorStore.setMergeCategoryName(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        <TextField
            fullWidth
            multiline
            id="taxonomy-editor-modal-description-field"
            label="Description"
            error={Boolean(taxonomyEditorStore.mergeCategoryDescriptionError)}
            helperText={taxonomyEditorStore.mergeCategoryDescriptionError}
            variant="outlined"
            value={taxonomyEditorStore.mergeCategoryDescription}
            onChange={e => taxonomyEditorStore.setMergeCategoryDescription(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
        />

        {/*<List className="selection-overview-list">*/}
        {/*    {taxonomyEditorStore.selection.map(s =>*/}
        {/*        <ListItem key={s.id}>*/}
        {/*            <PartCategorizationComponent cats={s.ancestors().reverse().map(n => n.data.label)}/>*/}
        {/*        </ListItem>*/}
        {/*    )}*/}
        {/*</List>*/}
    </EditorModalComponent>
})
