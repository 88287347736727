import React from "react";
import {Typography} from "@mui/material";

type Props = {
    title: string
}
export const DashBoardBadge: React.FC<Props> = ({title}) =>
    <div className="dashboard-badge">
        <Typography variant="subtitle1" component="p">{title}</Typography>
    </div>
