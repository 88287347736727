// noinspection JSUnusedLocalSymbols

import {HARDCODED_DPW_COMMON_SUPPLIER_TABLE_RAW} from "./hardcoded_dpw_raw";
import {CategoryOverlapData, CommonSupplierData, SupplierComparePartData} from "./hardcoded_dpw_types";

export namespace hardcoded_dpw {

    // Parameters
    const overlapping_categories = 150;
    const overlap_n_suppliers = 750;
    const overlap_master_supplier_spend_P = .21;
    const overlap_cmp_supplier_spend_P = .43;

    const master_total_spend = 596500000;
    const master_n_suppliers = 8796;
    const master_n_categories = 200;
    const master_unclassified_spend = 13300000;
    const compare_total_spend = 253300000;
    const compare_n_suppliers = 1245;
    const compare_n_categories = 400;
    const compare_unclassified_spend = 56500000;

    export const L1_CATEGORY_OVERLAPS: { [l1: string]: CategoryOverlapData } = {
        'Metals': {
            category_n_overlap: 4, // <- deduce from mappings?

            master_n_categories: 10, // <- deduce from master taxonomy?
            master_spend: master_total_spend * 0.23,
            master_spend_overlap: master_total_spend * 0.23 * 3 / 10 * 1.5,

            compare_n_categories: 5, // <- deduce from cmp taxonomy?
            compare_spend: compare_total_spend * 0.13,
            compare_spend_overlap: compare_total_spend * 0.13 * 3 / 5 * 0.8,
        },
        'Plastics': {
            category_n_overlap: 4, // <- deduce from mappings?

            master_n_categories: 60, // <- deduce from master taxonomy?
            master_spend: master_total_spend * 0.12,
            master_spend_overlap: master_total_spend * 0.23 * 4 / 60 * 3.5,

            compare_n_categories: 6, // <- deduce from cmp taxonomy?
            compare_spend: compare_total_spend * 0.6,
            compare_spend_overlap: compare_total_spend * 0.13 * 4 / 4 * 0.5,
        },
    }
    export const COMBINED_L1_OVERLAPS = Object.values(L1_CATEGORY_OVERLAPS).reduce(
        (total, d) => Object.fromEntries(Object.entries(d).map(
            ([k, v]) => ([k, v + (total[k] || 0)])
        )),
        {},
    ) as CategoryOverlapData;
    export const SELECTABLE_L1 = Object.keys(L1_CATEGORY_OVERLAPS);

    export const MASTER = {
        total_spend: master_total_spend,
        total_spend_overlap: master_total_spend * overlap_master_supplier_spend_P,
        n_suppliers: master_n_suppliers,
        n_categories: master_n_categories,
        unclassified_spend: master_unclassified_spend,

        spendConcentration_P: .87,
        ppv: 8400000,

        overlap_supplier_spend_P: overlap_master_supplier_spend_P,
        n_suppliers_overlap_P: overlap_n_suppliers / master_n_suppliers,
        n_categories_overlap_P: overlapping_categories / master_n_categories,
        unclassified_spend_P: master_unclassified_spend / master_total_spend,
    }
    export const COMPARE = {
        total_spend: compare_total_spend,
        total_spend_overlap: compare_total_spend * overlap_cmp_supplier_spend_P,
        n_suppliers: compare_n_suppliers,
        n_categories: compare_n_categories,
        unclassified_spend: compare_unclassified_spend,

        spendConcentration_P: .97,
        ppv: 19500000,

        overlap_supplier_spend_P: overlap_cmp_supplier_spend_P,
        n_suppliers_overlap_P: overlap_n_suppliers / compare_n_suppliers,
        n_categories_overlap_P: overlapping_categories / compare_n_categories,
        unclassified_spend_P: compare_unclassified_spend / compare_total_spend,
    }

    export const OVERLAP = {
        overlapping_categories,
        overlap_n_suppliers,
        overlap_total_combined_spend: MASTER.total_spend_overlap + COMPARE.total_spend_overlap,
    };

    const part_data_master_A: SupplierComparePartData[] = [
        {is_master: true, name: 'P4124', categories: ['Metals', 'Other'], spend: 24582.62},
        {is_master: true, name: 'P42313', categories: ['Metals', 'Other'], spend: 391},
        {is_master: true, name: 'P42314', categories: ['Metals', 'Other'], spend: 391},
        {is_master: true, name: 'P42315', categories: ['Metals', 'Sheets'], spend: 391},
        {is_master: true, name: 'P42316', categories: ['Rubber', 'Sheets'], spend: 391},
    ]
    const part_data_master_B: SupplierComparePartData[] = [
        {is_master: true, name: 'Q4124', spend: 24582.62, categories: ['Metals', 'Other']},
        {is_master: true, name: 'Q42313', categories: ['Metals', 'Sheets'], spend: 391},
        {is_master: true, name: 'Q42314', categories: ['Metals', 'Sheets'], spend: 391},
        {is_master: true, name: 'AXY67', categories: ['Metals', 'Other'], spend: 47601.25},
    ]
    const part_data_cmp_A: SupplierComparePartData[] = [
        {is_master: false, name: 'AX54', categories: ['Metals', 'Sheets'], spend: 9369.51},
        {is_master: false, name: 'AX55', categories: ['Metals', 'Sheets'], spend: 9369.51},
        {is_master: false, name: 'AX56', categories: ['Plastics', 'Sheets'], spend: 9369.51},
        {is_master: false, name: 'AXY67', categories: ['Metals', 'Sheets'], spend: 7466},
    ]
    const part_data_cmp_B: SupplierComparePartData[] = [
        {is_master: false, name: 'BX54', categories: ['Metals', 'Sheets'], spend: 9369.51},
        {is_master: false, name: 'BX55', categories: ['Metals', 'Sheets'], spend: 9369.51},
        {is_master: false, name: 'BX56', categories: ['Metals', 'Sheets'], spend: 9369.51},
        {is_master: false, name: 'BXY6', categories: ['Metals', 'Sheets'], spend: 7466},
    ]

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const COMMON_SUPPLIER_TABLE_DATA_OLD: Array<CommonSupplierData> = [
        {
            name: 'East Coast Supplier',
            type: 'Strategic',
            spend_master_ds: 73_747.87,
            spend_cmp_ds: 44_468.14,
            l1_categories: ['Metals', 'Rubber'],
            parts_master_ds: part_data_master_A,
            parts_cmp_ds: part_data_cmp_A,
        },
        {
            name: 'West Coast Supplier',
            type: 'Strategic',
            spend_master_ds: 73_747.87,
            spend_cmp_ds: 44_468.14,
            l1_categories: ['Metals', 'Plastics'],
            parts_master_ds: part_data_master_B,
            parts_cmp_ds: part_data_cmp_B,
        },
        {
            name: 'Google Cloud Service Provider',
            type: 'Transactional',
            spend_master_ds: 8_747.87,
            spend_cmp_ds: 13_466.14,
            l1_categories: ['IT', 'Hosting'],
            parts_master_ds: part_data_master_B,
            parts_cmp_ds: part_data_cmp_B,
        },
    ]

    export const COMMON_SUPPLIER_TABLE_DATA: CommonSupplierData[] = HARDCODED_DPW_COMMON_SUPPLIER_TABLE_RAW.map(s => {
        s.parts_master_ds.map(p => {
            p.is_master = true;
            return p;
        })
        s.parts_cmp_ds.map(p => {
            p.is_master = false;
            return p;
        })
        s.parts_master_ds.sort((a, b) => (b.spend || 0) - (a.spend || 0))
        s.parts_cmp_ds.sort((a, b) => (b.spend || 0) - (a.spend || 0))
        return s as CommonSupplierData;
    });

    export const DO_SCRAMBLE = true;

    export function _hackscramble(data: CommonSupplierData[]) {
        if (DO_SCRAMBLE) {
            const scrambled = data.filter(() => Math.random() > 0.5)
            scrambled.forEach(s => {
                s.parts_master_ds = s.parts_master_ds.filter(() => Math.random() > 0.5)
                s.parts_cmp_ds = s.parts_cmp_ds.filter(() => Math.random() > 0.5)
            })
            return scrambled
        } else {
            return data;
        }
    }
}
