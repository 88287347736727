import React from "react";
import {COLOR_CRITICAL, COLOR_OK, COLOR_WARN} from "../../style/colors";

export type RangeSpec = {
    // x < med: Ok
    // med <= x < high: Warn
    // x >= high: Critical
    med: number;
    high: number;
    reversed?: boolean;
    okColor?: string;
    warnColor?: string;
    criticalColor?: string;
}
type Props = { v: number, isPercentage?: boolean, vP?: number, rangeSpec?: RangeSpec };

function getColor(v, spec?: RangeSpec) {
    if (!spec) {
        return '';
    }
    const colors = [spec.okColor || COLOR_OK, spec.warnColor || COLOR_WARN, spec.criticalColor || COLOR_CRITICAL];
    let bucket = 2;
    if (v < spec.med) {
        bucket = 0;
    } else if (v < spec.high) {
        bucket = 1;
    }
    if (spec.reversed) {
        bucket = 2 - bucket;
    }
    return colors[bucket];
}

export const AnnotatedValueComponent: React.FC<Props> = ({v, isPercentage, vP, rangeSpec}) => {
    let str = isPercentage ? `${Math.round(v * 100)}%` : `${v}`;
    const color = getColor(v, rangeSpec);
    const style = {}
    if (color) {
        style['color'] = color;
    }
    if (vP !== undefined) {
        str += ` (${Math.round(vP * 100)}%)`;
    }
    return <span style={style}>{str}</span>
};
