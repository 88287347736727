import {Droppable} from "react-beautiful-dnd";
import {MappingTableItem} from "./MappingTableItem";
import {MappingTableItemType} from "./MappingTableTypes";
import React from "react";

type Props = {
    items: MappingTableItemType[]
    droppableId: string
    key: string
    disabled: boolean
    is_required: boolean
}

export const MappingMithraColumn: React.FC<Props> = ({items, droppableId, disabled, is_required}) => {

    return (
        <>
            <Droppable droppableId={droppableId} isDropDisabled={disabled}>
                {(provided) => (
                    <div ref={provided.innerRef} className={disabled ? "mithra-column disabled" : "mithra-column"}>
                        {items.map((item, index) => (
                            <MappingTableItem name={item.name} example={item.example} index={index} id={item.id}
                                              key={item.id}
                                              ai_result={item.ai_result}
                                              parentList={droppableId}
                                              column_letter={item.column_letter}></MappingTableItem>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </>
    );
}
