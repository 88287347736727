export enum InputStatusEnum {
    I_CONFIGURING = 0,
    I_PARSING = 1,
    I_READY = 2,
    I_ERROR = 3,
    I_CANCELLED = 4,
    I_DISCARDED = 5,
}

export enum JobStatusEnum {
    B_CONFIGURING = 0,
    B_PROCESSING = 1,
    B_READY = 2,
    B_BUSY = 3,
    B_ERROR = 4,
    B_CANCELLED = 5,
    B_DISCARDED = 6,
}

export function displayJobStatusEnum(s: JobStatusEnum): string {
    switch (s) {
        case JobStatusEnum.B_CONFIGURING:
            return 'Configuring'
        case JobStatusEnum.B_PROCESSING:
            return 'Parsing'
        case JobStatusEnum.B_READY:
            return 'Completed'
        case JobStatusEnum.B_BUSY:
            return 'Processing'
        case JobStatusEnum.B_ERROR:
            return 'Problem found';
        case JobStatusEnum.B_DISCARDED:
            return 'Removed';
        default:
            return '?';
    }
}

export function displayJobInputStatusEnum(s: InputStatusEnum): string {
    switch (s) {
        case InputStatusEnum.I_CONFIGURING:
            return 'Initializing'
        case InputStatusEnum.I_PARSING:
            return 'Parsing'
        case InputStatusEnum.I_READY:
            return 'Completed'
        case InputStatusEnum.I_ERROR:
            return 'Problem found';
        case InputStatusEnum.I_CANCELLED:
            return 'Cancelled';
        case InputStatusEnum.I_DISCARDED:
            return 'Removed';
        default:
            return '?';
    }
}

export function JobInputStatus_isEnd(j: InputStatusEnum): boolean {
    switch (j) {
        case InputStatusEnum.I_CONFIGURING:
        case InputStatusEnum.I_PARSING:
        case InputStatusEnum.I_READY:
        case InputStatusEnum.I_ERROR:
            return false;
        case InputStatusEnum.I_CANCELLED:
        case InputStatusEnum.I_DISCARDED:
            return true;
    }
}

export function JobStatus_isEnd(j: JobStatusEnum): boolean {
    switch (j) {
        case JobStatusEnum.B_CONFIGURING:
        case JobStatusEnum.B_PROCESSING:
        case JobStatusEnum.B_READY:
        case JobStatusEnum.B_BUSY:
        case JobStatusEnum.B_ERROR:
            return false;
        case JobStatusEnum.B_CANCELLED:
        case JobStatusEnum.B_DISCARDED:
            return true;
    }
}

export function JobInputStatus_isPending(s: InputStatusEnum) {
    // See job_processing/state_enums.py
    switch (s) {
        case InputStatusEnum.I_CONFIGURING:
        case InputStatusEnum.I_PARSING:
        case InputStatusEnum.I_ERROR:
            return true;
        default:
            return false;

    }
}

export function BagInput_Predict(current: InputStatusEnum) {
    // See job_processing/state_enums.py
    switch (current) {
        case InputStatusEnum.I_CONFIGURING:
            return InputStatusEnum.I_PARSING;
        default:
            return current;
    }
}
