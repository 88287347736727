import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {m_taxonomy} from "../../services/classes/TaxonomyClasses";
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper} from "@mui/material";
import {ArrowDropDown, Redo, Undo} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";

type Props = {}
export const HistoryButton: React.FC<Props> = observer(() => {
    const {taxonomyManagerStore} = useStores();

    const [openUndo, setOpenUndo] = React.useState(false);
    const [openRedo, setOpenRedo] = React.useState(false);
    const anchorRefUndo = React.useRef<HTMLDivElement>(null);
    const anchorRefRedo = React.useRef<HTMLDivElement>(null);

    const handleToggle = (undo: boolean) => {
        if (undo)
            setOpenUndo((prevOpen) => !prevOpen)
        else
            setOpenRedo((prevOpen) => !prevOpen)
    };
    const close = (undo: boolean) => {
        if (undo)
            setOpenUndo(false)
        else
            setOpenRedo(false)
    };
    const handleClick = (undo: boolean, gotoOperationNumber: number) => {
        taxonomyManagerStore.gotoHistoryState(gotoOperationNumber)
        close(undo);
    }

    // TODO: Add the single or multiple selection for dropdown

    return <>
        <ButtonGroup
            className="toolbar-composite-btn"
            color="primary"
            variant="outlined"
            disabled={!taxonomyManagerStore.undoAllowed}
            ref={anchorRefUndo}
            aria-label="split button">
            <Button className="large-icon-btn only-icon-btn" onClick={() => {
            }}>
                {/*Not sure why this is needed, but when the first element is an icon an unknown margin is added*/}
                &nbsp;
                <Undo/>
            </Button>
            <Button
                // className=""
                // color="primary"
                size="small"
                // variant="outlined"
                onClick={() => handleToggle(true)}
                aria-controls={openUndo ? 'menu-list-grow' : undefined}
                aria-expanded={openUndo ? true : undefined}
                aria-haspopup="menu">
                <ArrowDropDown/>
            </Button>
        </ButtonGroup>
        <Popper className="history-popper"
                open={openUndo}
                anchorEl={anchorRefUndo.current}
                role={undefined}
                transition
                disablePortal
        >
            {({TransitionProps, placement}) => {
                const history = taxonomyManagerStore.undoHistory
                return (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            zIndex: 10,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => close(true)}>
                                <MenuList autoFocusItem={openUndo} id="menu-list-grow">
                                    {history.map(({operation, undoNumber}) =>
                                        <MenuItem key={operation.operation_number}
                                                  onClick={() => handleClick(true, undoNumber)}>
                                            <HistoryItem history={operation}/>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                );
            }}
        </Popper>

        <ButtonGroup
            className="toolbar-composite-btn"
            color="primary"
            variant="outlined"
            disabled={!taxonomyManagerStore.redoAllowed}
            ref={anchorRefRedo}
            aria-label="split button">
            <Button className="large-icon-btn only-icon-btn" onClick={() => {
            }}>
                &nbsp;
                <Redo/>
            </Button>
            <Button
                // className=""
                // color="primary"
                size="small"
                // variant="outlined"
                onClick={() => handleToggle(false)}
                aria-controls={openRedo ? 'menu-list-grow' : undefined}
                aria-expanded={openRedo ? true : undefined}
                aria-haspopup="menu">
                <ArrowDropDown/>
            </Button>
        </ButtonGroup>
        <Popper className="history-popper"
                open={openRedo}
                anchorEl={anchorRefRedo.current}
                role={undefined}
                transition
                disablePortal
        >
            {({TransitionProps, placement}) => {
                const history = taxonomyManagerStore.redoHistory
                return (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            zIndex: 10,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => close(false)}>
                                <MenuList autoFocusItem={openRedo} id="menu-list-grow">
                                    {history.map(operation =>
                                        <MenuItem key={operation.operation_number}
                                                  onClick={() => handleClick(false, operation.operation_number)}>
                                            <HistoryItem history={operation}/>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                );
            }}
        </Popper>
    </>
})

const HistoryItem: React.FC<{ history: m_taxonomy.TaxonomyHistory }> = ({history}) => {
    switch (history.operation_name as m_taxonomy.Operation) {
        case m_taxonomy.Operation.Add:
            return <>Add</>
        case m_taxonomy.Operation.Move:
            return <>Move</>
        case m_taxonomy.Operation.Update:
            return <>Rename</>
        case m_taxonomy.Operation.Delete:
            return <>Delete</>
        case m_taxonomy.Operation.Merge:
            return <>Merge</>
    }
}

