export enum OpportunityTypesEnum {
    ppv,
    cashflow,
}

export enum OpportunityStatusEnum {
    Identified,
    Qualified,
    Realized,
    Disqualified,
}

interface OpportunityStatus {
    id: number
    timestamp: string
    status: OpportunityStatusEnum
    opportunity: number
}

interface _Opportunity_POST {
    data_bag: number // TODO: Rename to databag
    current_status: OpportunityStatusEnum
    total_opportunity: number
}

export interface Opportunity extends _Opportunity_POST {
    id: number
    opportunity_type_label: string
    current_status_label: string
    latest_status: OpportunityStatus
    current_status: OpportunityStatusEnum
    opportunity_type: OpportunityTypesEnum
    total_opportunity: number
    data_bag: number // TODO: Rename to databag
}

type _PpvOpportunity_Data = {
    part_id: string
    p_spend_sum: number
    p_unit_cost_min: number
    p_unit_cost_avg: number
    group_size: number
    p_quantity_sum: number
}
export type PpvOpportunity_POST = _Opportunity_POST & _PpvOpportunity_Data
export type PpvOpportunity = Opportunity & _PpvOpportunity_Data


type _CashFlowOpportunity_Data = {
    supplier_id: string
    supplier_annual_spend: number
    part_payment_term_diff_avg: number
}
export type CashFlowOpportunity_POST = _Opportunity_POST & _CashFlowOpportunity_Data
export type CashFlowOpportunity = Opportunity & _CashFlowOpportunity_Data
