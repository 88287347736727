import {colors} from "./colors";
import {createTheme, ThemeOptions, unstable_createMuiStrictModeTheme} from "@mui/material";
import {environment} from "../env";
import {Theme} from "@mui/material/styles";

// @see: https://material-ui.com/customization/default-theme/
export const theme: ThemeOptions = {
    palette: {...colors},
    typography: {
        fontSize: 14,
        h1: {
            fontSize: '6rem',
        },
        h2: {
            // Page title
            fontSize: '3.75rem',
        },
        h3: {
            fontSize: '3rem',
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
        },
    },
    // spacing = N * 8px (https://material-ui.com/customization/spacing/)
};

// const v4Theme = adaptV4Theme(theme)
export const _theme = environment.production ? createTheme(theme) : unstable_createMuiStrictModeTheme(theme);

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}

