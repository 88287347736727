import React from "react";
import {observer} from "mobx-react-lite";
import {TextField, Typography} from "@mui/material";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {useStores} from "../../../../stores";

export const ConfirmSendForApproval: React.FC = observer(() => {
    const {categorizationReviewStore} = useStores();

    return <EditorModalComponent
        titleId={"review-modal-title"}
        className="review-model-component confirm-send-for-approval-component"
        title="Should the categorization be submitted for approval?"
        saveLabel={'Send'}
        canSave={!categorizationReviewStore.isBusySendForApproval}
        onSave={() => {
            categorizationReviewStore.sendForApproval()
        }}
        isSaving={categorizationReviewStore.isBusySendForApproval}
        onClose={() => categorizationReviewStore.setSendForApprovalDialog(false)}
        isError={categorizationReviewStore.isSendForApprovalError}
    >
        <Typography variant="body1"></Typography>

        <TextField
            multiline
            fullWidth
            id="review-editor-modal-approval-note-field"
            label="Notes for the reviewer (optional)"
            // error={false}
            // helperText={}
            variant="outlined"
            value={categorizationReviewStore.approvalNotes}
            onChange={e => categorizationReviewStore.setApprovalNotes(e.target.value)}
            disabled={categorizationReviewStore.isBusySendForApproval}
        />
    </EditorModalComponent>
})
