import {TaxonomyMapperStore} from "./TaxonomyMapperStore";
import {TaxonomyMapSelectionDelegate} from "./TaxonomyMapSelectionDelegate";
import {
    CollapsibleIndentTreeBuilder,
    ExtendedTree
} from "../../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";
import {
    MatchCategoriesTreeVisualizationController,
    MatchNodeDataType
} from "../../../components/visualization/match-categories-tree-v2/MatchCategoriesTreeVisualization";
import {MatKpiTreeData} from "../../../services/ApiTypes";

export class TaxonomyMapTreeControllerDelegate {
    private _controller?: MatchCategoriesTreeVisualizationController;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private taxonomyMapperStore: TaxonomyMapperStore,
        private taxonomyMapSelectionDelegate: TaxonomyMapSelectionDelegate,
    ) {
        // Not observable
    }

    setController(controller: MatchCategoriesTreeVisualizationController) {
        this._controller = controller;
    }

    get controller() {
        return this._controller as MatchCategoriesTreeVisualizationController;
    }

    onClickNode(d: ExtendedTree<MatKpiTreeData>, left: boolean) {
        const tree = (left ? this.controller.leftTree : this.controller.rightTree);
        if (tree.disabledClick) return;
        if (!d.canOpen) return;

        CollapsibleIndentTreeBuilder.setNodeCollapsed(d, !d.collapsed)

        // This can be optimized to only re-render one (or a part of) the tree
        this.controller.resetYAxis()
    }

    openRightTreeToNodes(rightIds: Set<number>) {
        this.controller.rightTree.openOnlyNode(d => rightIds.has(d.data.id))
        this.controller.resetYAxis()
    }

    openLeftTreeToNodes(leftIds: Set<number>) {
        this.controller.leftTree.openOnlyNode(d => leftIds.has(d.data.id))
        this.controller.resetYAxis();
    }

    alignNodes(leftIds: number[], rightIds: number[]) {
        const leftYAxis = this.controller.leftTree.yAxis;
        const rightYAxis = this.controller.rightTree.yAxis;
        if (!leftYAxis || !rightYAxis) return;

        const leftYs = this.controller.leftTree.getNodes()
            .filter(n => leftIds.includes(n.data.id))
            .map(n => leftYAxis(String(n.data.id)))
            .filter(y => y !== undefined) as number[]
        const rightYs = this.controller.rightTree.getNodes()
            .filter(n => rightIds.includes(n.data.id))
            .map(n => rightYAxis(String(n.data.id)))
            .filter(y => y !== undefined) as number[]
        const minLeft = Math.min(...leftYs);
        const minRight = Math.min(...rightYs);
        let dY = minLeft - minRight;

        console.log('alignNodes()', leftIds.length, rightIds.length, dY);
        if (!Number.isFinite(dY)) {
            dY = 0;
        }

        if (dY > 0) {
            this.controller.setYOffsets(0, dY, leftIds[0]);
        } else {
            this.controller.setYOffsets(-dY, 0, leftIds[0]);
        }
    }

    resetTreeAlignment() {
        this.controller.setYOffsets(0, 0);
    }

    openTreeCompletely(left: boolean) {
        const tree = left ? this.controller.leftTree : this.controller.rightTree;
        tree.openAll()
        this.controller.resetYAxis();
    }

    reset() {
        const resetNode = (n: ExtendedTree<MatchNodeDataType>) => {
            n.data.highlighted = false;
            n.data.selected = false;
            n.data.childSelected = false;
        };
        this.controller.leftTree.beforeEach(resetNode)
        this.controller.rightTree.beforeEach(resetNode)
        this.controller.leftTree.openAll()
        this.controller.rightTree.openAll()
        this.controller.resetYAxis()
    }
}
