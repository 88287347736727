import {
    MatPartReviewRow,
    MatPartReviewRowWithContext,
    MatReviewLevelStatisticsTreeSerializer,
    ReviewChoice
} from "./MaterializedClasses";
import {Categories} from "./AiClasses";
import {
    fromFieldsCategories,
    fromFieldsCategoriesOrUncat,
    fromSimpleFieldsCategoriesOrUncat,
    pickDefinedOrUncat
} from "../ApiHelpers";
import {MatPartReviewRowState} from "./MatReviewClasses";

export function getContextReviewCategory(part: MatPartReviewRowWithContext, taxonomySize: number): Categories | false {
    return fromSimpleFieldsCategoriesOrUncat(part.context, taxonomySize);
}

export function getActiveReviewCategory(part: MatPartReviewRow, taxonomySize): Categories {
    const prevCats = fromFieldsCategories(part, 'p_prev_cat', taxonomySize)
    const algCats = fromFieldsCategories(part, 'p_suggested_cat', taxonomySize)
    const reviewedCats = fromFieldsCategories(part, 'p_review_cat', taxonomySize)
    return pickDefinedOrUncat([reviewedCats, algCats, prevCats], taxonomySize)
}

export function getSpecificCategorization(part: MatPartReviewRow, taxonomySize: number, spec: ApprovalCategorization): Categories | false {
    switch (spec) {
        case 'input':
            return fromFieldsCategoriesOrUncat(part, 'p_prev_cat', taxonomySize)
        case 'ai':
            return fromFieldsCategories(part, 'p_suggested_cat', taxonomySize);
        case 'review|ai':
            return pickDefinedOrUncat([
                fromFieldsCategories(part, 'p_review_cat', taxonomySize),
                fromFieldsCategories(part, 'p_suggested_cat', taxonomySize),
            ], taxonomySize)
        case 'none':
            return false
    }
}

export type ApprovalCategorization = 'input'
    | 'ai'
    | 'review|ai'
    | 'none'

export enum CombinedReviewState {
    SOME_NOT_SET,
    ALL_ACCEPTED,
    ALL_REJECTED,
    MIXED,
}

export type CombinedState = {
    reviewState: CombinedReviewState
    allSameCategory: false | string[]
}

export function calcCombinedState(parts: MatPartReviewRowState[], taxonomySize: number): CombinedState {
    let allSameCategory: false | string[] | undefined = undefined;
    let reviewState: CombinedReviewState | undefined = undefined;
    let prevChoice: ReviewChoice | undefined = undefined;
    for (let part of parts) {
        // Look for differences in the categorization
        const category = getActiveReviewCategory(part, taxonomySize);
        if (allSameCategory === undefined) {
            allSameCategory = category
        } else if (allSameCategory !== false) {
            const difference = allSameCategory.some((c, i) => c !== category[i])
            if (difference) {
                allSameCategory = false;
            }
        }
        // Look for differences in the review rejection
        if (part.review_choice === ReviewChoice.ACCEPT) {
            if (prevChoice === undefined) prevChoice = part.review_choice;
        } else if (part.review_choice === ReviewChoice.REJECT) {
            if (prevChoice === undefined) prevChoice = part.review_choice;
        } else {
            reviewState = CombinedReviewState.SOME_NOT_SET;
        }
        if (reviewState !== CombinedReviewState.SOME_NOT_SET && part.review_choice !== prevChoice) {
            reviewState = CombinedReviewState.MIXED
        }
    }
    if (reviewState === undefined) {
        if (prevChoice === ReviewChoice.ACCEPT) {
            reviewState = CombinedReviewState.ALL_ACCEPTED
        } else if (prevChoice === ReviewChoice.REJECT) {
            reviewState = CombinedReviewState.ALL_REJECTED
        } else {
            console.warn('Unexpected case on parts', parts)
            reviewState = CombinedReviewState.SOME_NOT_SET
        }
    }
    return {reviewState, allSameCategory: allSameCategory || false}
}

export function selectMatReviewLevelStatistics(tree: MatReviewLevelStatisticsTreeSerializer, categorySelection: string[]): MatReviewLevelStatisticsTreeSerializer[] {
    const result = [tree]
    if (!categorySelection) return result;
    for (const category of categorySelection) {
        let parent = result.at(-1) as MatReviewLevelStatisticsTreeSerializer;
        let node = parent.children.find(n => n.label === category)
        if (!node) {
            throw new Error(`Cannot find category ${categorySelection} in tree`)
        }
        result.push(node);
    }
    return result;
}
