import {BagStore} from "./BagStore";
import LoadingBarStore from "./LoadingBarStore";
import AuthStore from "./AuthStore";
import {makeAutoObservable, runInAction} from "mobx";
import {Bag, canChangeOnServer} from "../services/classes/Bag";
import {History, Location} from "history";
import {JobRouteMatch, MithraHistoryState, routes} from "../routing/routes";
import {match as Match} from "react-router";
import MithraMaterializedApi from "../services/MithraMaterializedApi";

export class BagLoadingStore {

    isInSync = true;

    bagLoading = true;

    bagNotFound = false;

    error: any;

    isUploading = false;

    droppedFile?: File;

    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private loadingBarStore: LoadingBarStore,
        private authStore: AuthStore,
    ) {
        makeAutoObservable(this)
    }

    loadBag(bagId: number): Promise<Bag> {
        this.loadingBarStore.start(true);
        this.isInSync = false;
        return this.api.getBag(bagId)
            .then(r => {
                const bag = r.data;
                this.loadingBarStore.setComplete();
                this.bagStore.setBag(bag);
                return bag;
            })
            .finally(() => runInAction(() => this.isInSync = true))
    }

    loadBagWithoutLoadingStores(bagId: number): Promise<Bag> {
        this.loadingBarStore.start(true);
        this.isInSync = false;
        return this.api.getBag(bagId)
            .then(r => {
                const bag = r.data;
                this.loadingBarStore.setComplete();
                this.bagStore.set(bag);
                return bag;
            })
            .finally(() => runInAction(() => this.isInSync = true))
    }

    /**
     * @deprecated In favor loadBagWithoutLoadingStores
     */
    initBagById(bagId: number, history: History<unknown>) {
        if (!this.isInSync) {
            // Bag is already being retrieved
            return undefined;
        }
        if (this.bagNotFound) {
            return null;
        }
        if (this.bagStore.bag) {
            // Bag is already present
            return this.bagStore.bagId;
        }
        if (isNaN(bagId)) {
            this.bagNotFound = true;
            return null;
        }
        // TODO: The loading of bags needs to be revised after authentication upgrade
        // this.loadBag(bagId)
        //     .catch(err => {
        //         if (isTokenExpired(err)) {
        //             this.authStore.expire();
        //             history.push(routes.login);
        //         } else {
        //             // TODO what to do when bag cannot be found on the server anymore?
        //             this.bagNotFound = true;
        //         }
        //     });
        return bagId;
    }

    /**
     * Initialize the bag store
     * @return Best estimated bag id (Beware; it might be invalid)
     */
    initBag(
        location: Location<MithraHistoryState>,
        history: History<unknown>,
        match: Match<JobRouteMatch>
    ): number | undefined | null {
        // TODO: performance gain can be achieved by limiting this call (i.e. deps = [])
        if (!this.isInSync) {
            // Bag is already being retrieved
            return undefined;
        }
        if (this.bagNotFound) {
            return null;
        }
        if (this.bagStore.bag) {
            // Bag is already present
            return this.bagStore.bagId;
        }
        if (location.state?.bag) {
            // TODO: this is more complicated then it looks,
            // - How to check how much time has passed?
            // - Tokens might need to be refreshed
            // Attempt to interpret bag from Redirect
            const bag = location.state.bag;
            const dNow = new Date()
            const dJob = new Date(bag.current_status.timestamp);
            const dDiff = dNow.getTime() - dJob.getTime();
            const isRecent = dDiff < 1000;
            // Check if this is a intermediate state
            const bagId = bag.id;
            if (!isRecent && canChangeOnServer(bag)) {
                console.debug(`Reloading bag ${bagId} (${dDiff / 1000}s old) from location.state`, bag);
                // Retrieve from server anyways
                this.loadBag(bagId)
                    .catch(err => {
                        const unAuth = this.authStore.authentication.catchApiError(err);
                        if (unAuth) {
                            history.push(routes.login);
                            return
                        }

                        this.bagNotFound = true;
                        // Remove state, as it is invalid
                        console.warn('UNTESTED: What to do when a bag is failed to reload from STATE HISTORY?')
                        history.replace(location.pathname, undefined);
                    });
            } else {
                console.debug(`Loading bag ${bagId} (${dDiff / 1000}s old) from location.state`, bag);
                this.bagStore.setBag(bag);
            }
            return bagId;
        } else {
            // Attempt to interpret job from URL
            console.debug(`Load job ${match.params.id} from URL`);
            const jobId = Number(match.params.id);
            if (isNaN(jobId)) {
                this.bagNotFound = true;
                return null;
            }
            this.loadBag(jobId)
                .catch(err => {
                    const unAuth = this.authStore.authentication.catchApiError(err);
                    if (unAuth) {
                        history.push(routes.login);
                        return
                    }

                    // TODO what to do when bag cannot be found on the server anymore?
                    this.bagNotFound = true;
                });
            return jobId;
        }
    }
}
