import {Route} from "react-router-dom";
import React, {ReactNode} from "react";
import {RouteComponentProps} from "react-router";

interface Props {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;

    [routeProps: string]: ReactNode;
}

export const WrappedRoute: React.FC<Props> = ({component: Component, ...routeProps}) => {
    const render = props => {
        if (routeProps.w) {
            const Wrapper = routeProps.w as any;
            return <Wrapper><Component {...props}/></Wrapper>;
        }
        return <Component {...props}/>
    }
    return <Route {...routeProps} render={render}/>;
}
