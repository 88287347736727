import {ChangeEvent, useState} from 'react';
import {useStores} from "../../stores";
import Button from '@mui/material/Button';
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {CircularProgress} from "@mui/material";


function FileUploadSingle() {
    const {dataIngestionStore} = useStores();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        console.log('File changed...');

        if (e.target.files) {
            setIsLoading(true);

            /*
            * 1) Upload file
            * 2) Start AI
            * 3) (done by wrapper) df getter
            * 3) (done by wrapper) get mappings
            * 3) (done by wrapper) allowed op
            * 3) (done by wrapper) data ing
            * */

            dataIngestionStore.uploadDatafile(e.target.files[0])
                .then((dataFileId) => dataIngestionStore.startAiMapping(dataFileId))
                .then(() => history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataIngestionStore.dataFileId)})))
                .catch((error) => {
                    console.error('Catch in handleFileChange');
                    console.error('Error: ', error);
                    e.target.files = null;
                })
                .finally(() => {
                    setIsLoading(false);
                })


        }
    };

    return (
        <Button variant="contained" component="label"
                disabled={dataIngestionStore.datasetName == null || dataIngestionStore.datasetName.trim() === '' || isLoading}>

            {isLoading ? (
                <CircularProgress size={24}/>
            ) : (
                <>
                    Upload
                    <input
                        hidden
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                        onChange={handleFileChange}
                    />
                </>
            )}

        </Button>
    )
}

export default FileUploadSingle;