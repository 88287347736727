import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, CircularProgress, Grid} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {routes} from "../../routing/routes";
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {generatePath, useHistory} from "react-router";

type Props = {
    defaultPage: "dataset_upload" | "data_mapping" | "data_check" | "data_finish"
}
export const DataIngestionStepper: React.FC<Props> = observer(({defaultPage}) => {
    const {dataIngestionStore, p} = useStores();
    const history = useHistory();


    useEffect(() => {
    }, [dataIngestionStore, p])
    const [isLoading, setIsLoading] = useState(false);


    const page = dataIngestionStore.page || defaultPage;
    return <div className="data-ingestion data-mapping-table">
        <Grid container justifyContent="space-arround" style={{marginTop: "2em"}}>

            <Grid item xs={2} className="text-center align-with-stepper">
                {page === 'dataset_upload' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() =>{ 
                            history.push(generatePath(routes.data_ingestion))
                            dataIngestionStore.resetIngestionStore();
                            dataIngestionStore.resetErrorToShow();
                            }}>
                        Back
                    </Button>
                }
                {page === 'data_mapping' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            history.push(generatePath(routes.data_ingestion))
                            dataIngestionStore.resetIngestionStore();
                            dataIngestionStore.resetErrorToShow();
                            }}>
                        Back
                    </Button>
                }
                {page === 'data_check' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            dataIngestionStore.navigateToPage('data_mapping')
                        }}
                        //FIXME: (FINE FOR NOW) for now we use the naviateToPage method, but we should use the history.push method with the dataFileId
                        // onClick={() =>  history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataIngestionStore.dataFileId)}))}
                    >
                        Back
                    </Button>
                }
            </Grid>

            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={dataIngestionStore.step}>
                    <Step>
                        <StepLabel>Add file</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Data mapping</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Check</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Finish</StepLabel>
                    </Step>
                </Stepper>
            </Grid>

            <Grid item xs={2} className="text-center">
                {page === 'data_mapping' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={!dataIngestionStore.all_required_columns_mapped || isLoading}
                        onClick={async () => {
                            setIsLoading(true);
                            try {
                                await dataIngestionStore.postDataMappingsList();
                                dataIngestionStore.navigateToPage('data_check')

                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    >
                        {isLoading ? <CircularProgress size={24}/> : 'Next'}
                    </Button>
                }

                {page === 'data_check' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        onClick={() => {
                            dataIngestionStore.navigateToPage('data_finish')
                        }}
                    >
                        Next
                    </Button>
                }
            </Grid>
        </Grid>
    </div>
});
