import MithraMaterializedApi from "../services/MithraMaterializedApi";
import ProfileStore from "./ProfileStore";
import {makeAutoObservable} from "mobx";
import {AiOption, ApplyTaxonomyMappingAiDelegate} from "./ai/ApplyTaxonomyMappingAiDelegate";
import {BagStore} from "./BagStore";
import {TaxonomyMapperStore} from "../pages/taxonomy-mapper/store/TaxonomyMapperStore";
import {CategorizationStore} from "./CategorizationStore";

export class AiManagerStore {
    readonly applyTaxonomyMapping = new ApplyTaxonomyMappingAiDelegate(
        this, this.categorizationStore, this.api, this.profileStore, this.bagStore, this.taxonomyMapStore
    );

    public aiConfig: AiOption = 'base';

    setAiConfig(aiConfig: AiOption) {
        this.aiConfig = aiConfig;
    }

    constructor(
        private api: MithraMaterializedApi,
        private profileStore: ProfileStore,
        private bagStore: BagStore,
        private categorizationStore: CategorizationStore,
        private taxonomyMapStore: TaxonomyMapperStore,
    ) {
        makeAutoObservable(this);
    }
}