import React from "react";
import {useStores} from "../../../stores";
import {Bag} from "../../../services/classes/Bag";
import {Grid, Input, Slider, Tab, Tabs, Typography} from "@mui/material";
import {kpiToReadableString} from "../../../services/ApiHelpers";
import {HierarchicalSankeyChart, Options} from "../../../components/visualization/sankey/HierarchicalSankeyChart";
import {LoadingSpinnerPie} from "../../../components/loading-pie/LoadingSpinnerPie";
import './FilteredSankeyComponent.scss'
import {BagKpiManager} from "../../../stores/managers/BagKpiManager";
import {observer} from "mobx-react-lite";

export const FilteredSankeyComponent: React.FC = observer(() => {
    const {bagStore, p} = useStores();
    const bag = bagStore.bag as Bag;
    if (!bag) throw new Error();

    // Configure
    const selectedKpi = bagStore.kpi.selectedGraphKpi || 'spend';
    const [minFilter, setMinFilter] = React.useState(0); // TODO CAT-76: Make it min and max filter

    const data = bagStore.kpi.getFlatTaxonomyData(true, minFilter);
    let options: Options = {
        height: 'auto',
        removeRoot: true,
        minValueForLabel: 0,
        maxDepth: bag.taxonomy_size,
    }
    if (data) {
        options = {
            ...options,
            ...p.getCategoryViewConfig(selectedKpi, data.links.length, data.links[0]?.value),
        }
    }

    return <div className="filtered-sankey-visualization">
        <Grid container justifyContent="center">
            <Grid item xs={6}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Tabs
                            centered={true}
                            value={selectedKpi}
                            onChange={(e, kpi) => bagStore.kpi.setSelectedGraphKpi(kpi)}>
                            {BagKpiManager.AVAILABLE_KPIS.map((kpi, i) =>
                                <Tab key={i}
                                     label={kpiToReadableString(kpi, p.currencySymbol)}
                                     value={kpi}/>)
                            }
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <Typography id="filter-slider-label" gutterBottom>
                            Filter
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Slider
                            aria-labelledby="filter-slider-label"
                            valueLabelFormat={value => value === 0 ? 'Off' : `${value}`}
                            valueLabelDisplay="auto"
                            step={10000 / 10}
                            marks
                            min={0}
                            max={10000}
                            value={minFilter}
                            onChange={(_, v) => setMinFilter(v as any)}/>
                    </Grid>
                    <Grid item>
                        {/*onBlur={handleBlur}*/}
                        <Input
                            value={minFilter}
                            margin="dense"
                            onChange={event => setMinFilter(event.target.value === '' ? 0 : Number(event.target.value))}
                            inputProps={{
                                step: 10,
                                min: 0,
                                // max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <div className="small-loading-spinner">
                            {bagStore.kpi.taxonomyGraphReloading && <LoadingSpinnerPie/>}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            {data &&
                <Grid item xs={12}>
                    <HierarchicalSankeyChart data={data} kpi={selectedKpi} options={options}/>
                </Grid>
                || (bagStore.kpi.taxonomyGraphLoading && !bagStore.kpi.taxonomyGraphReloading &&
                    <Grid item className="loading-mid-page">
                        <LoadingSpinnerPie/>
                    </Grid>
                )}
        </Grid>
    </div>
});
