import {Observable, Subscription} from "rxjs";
import {action, computed, makeObservable, observable} from "mobx";
import {AxiosResponse} from "axios";

export class AxoisRequestManager<I, R> {
    public result: R | undefined;
    public error = '';
    public busy = false;

    private sub: Subscription | undefined;

    constructor(private requester: (i: I) => Observable<AxiosResponse<R>>) {
        makeObservable(this, {
            result: observable,
            error: observable,
            busy: observable,
            isReloading: computed,

            cleanup: action,

            request: false,
            _setResult: action,
            _setError: action,
        })
    }

    get isReloading() {
        return this.busy && this.result;
    }

    request(i: I): Subscription {
        console.debug('Request triggered', i);
        if (this.sub) this.sub.unsubscribe();
        this.busy = true;
        this.sub = this.requester(i).subscribe({
            next: r => this._setResult(r.data),
            error: err => this._setError(err.message),
        })
        return this.sub;
    }

    _setResult(r: R) {
        console.debug('Request completed', r);
        this.result = r;
        this.error = '';
        this.busy = false;
    }

    _setError(msg: string) {
        console.debug('Request errored', msg);
        this.result = undefined;
        this.error = msg;
        this.busy = false;
    }

    cleanup() {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
        this.result = undefined;
        this.error = '';
        this.busy = false;
    }
}

export class RequestManager<I, R> {
    public result: R | undefined;
    public error = '';
    public busy = false;

    private sub: Subscription | undefined;

    constructor(private requester: (i: I) => Observable<R>) {
        makeObservable(this, {
            result: observable,
            error: observable,
            busy: observable,
            isReloading: computed,

            cleanup: action,

            request: false,
            _setResult: action,
            _setError: action,
        })
    }

    get isReloading() {
        return this.busy && this.result;
    }

    request(i: I): Subscription {
        console.debug('Request triggered', i);
        if (this.sub) this.sub.unsubscribe();
        this.busy = true;
        this.sub = this.requester(i).subscribe({
            next: r => this._setResult(r),
            error: err => this._setError(err.message),
        })
        return this.sub;
    }

    _setResult(r: R) {
        console.debug('Request completed', r);
        this.result = r;
        this.error = '';
        this.busy = false;
    }

    _setError(msg: string) {
        console.debug('Request errored', msg);
        this.result = undefined;
        this.error = msg;
        this.busy = false;
    }

    cleanup() {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
        this.result = undefined;
        this.error = '';
        this.busy = false;
    }
}

export class GenericRequestManager<R> {
    public result: R | undefined;
    public error = '';
    public busy = false;

    private sub: Subscription | undefined;

    constructor() {
        makeObservable(this, {
            result: observable,
            error: observable,
            busy: observable,

            cleanup: action,

            request: false,
            _setResult: action,
            _setError: action,
        })
    }

    request(request: Observable<R>) {
        if (this.sub) this.sub.unsubscribe();
        this.busy = true;
        this.sub = request.subscribe({
            next: r => this._setResult(r),
            error: err => this._setError(err.message),
        })
    }

    _setResult(r: R) {
        this.result = r;
        this.error = '';
        this.busy = false;
    }

    _setError(msg: string) {
        this.result = undefined;
        this.error = msg;
        this.busy = false;
    }

    cleanup() {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
        this.result = undefined;
        this.error = '';
        this.busy = false;
    }
}
