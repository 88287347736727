import {Typography} from "@mui/material";
import React from "react";

export default () => <>
    <Typography color="textSecondary" gutterBottom>
        Cash Flow Optimization
    </Typography>
    <Typography component="p">
        By optimizing cash flow you can benefit from internal interest rates
    </Typography>
</>
