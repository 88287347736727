import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {Button, Dialog, Grid, TextField, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {ApprovalRouteMatch, MithraHistoryState, routes} from "../../../routing/routes";
import {useHistory} from "react-router";
import {ApprovalStatusEnum, ApprovalTypeEnum} from "../../../services/classes/AiClasses";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import moment from "moment";
import {APPROVAL_DIALOG_TITLE_ID} from "../ApprovalDialog";
import {TaxonomyApprovalAcceptDialog} from "./TaxonomyApprovalAcceptDialog";
import {TaxonomyApprovalChanges} from "./TaxonomyApprovalChanges";


export const ApprovalTaxonomyPage: React.FC = observer(() => {
    const routeMatch = useRouteMatch<ApprovalRouteMatch>();
    const history = useHistory<MithraHistoryState>();
    const {approvalStore} = useStores();

    // The magic that loads the data
    useEffect(() => approvalStore.onLoadApprovalPage(ApprovalTypeEnum.TAXONOMY, routeMatch, history),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const a = approvalStore.taxonomyApproval;
    const canEdit = a && ApprovalStatusEnum.userCanUpdate(a.current_status.status);
    const dateStr = moment(a?.current_status.timestamp).format('L LT')
    return <KoiFacade
        title="Review Taxonomy approval"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<Typography>
            {a && (
                `${ApprovalStore.showRequestType(a.request_type)} ―
                 ${a.created_by.first_name} ${a.created_by.last_name} ―
                 ${dateStr}`
            )}
        </Typography>}
        back={routes.approval}
        className="approval-page taxonomy-approval-page"
    >
        <Dialog
            open={approvalStore.isAcceptApprovalDialogOpen}
            onClose={() => approvalStore.clickAwayApprovalDialog()}
            aria-labelledby={APPROVAL_DIALOG_TITLE_ID}
            maxWidth="lg"
        >
            {approvalStore.isAcceptApprovalDialogOpen && <TaxonomyApprovalAcceptDialog/>}
        </Dialog>
        <Grid container justifyContent="center" alignItems="flex-end">
            <Grid item md={6} xs={10}>
                <TextField
                    multiline
                    fullWidth
                    label="Notes for the reviewer"
                    variant="filled"
                    value={a?.notes || ''}
                    inputProps={{readOnly: true}}
                />
                {a?.feedback_notes && <>
                    <br/>
                    <TextField
                        multiline
                        fullWidth
                        label="Notes send back"
                        variant="filled"
                        value={a?.feedback_notes || ''}
                        inputProps={{readOnly: true}}
                    />
                </>}
            </Grid>
            <Grid item md={2} xs={2}
                  container direction="column" alignItems="center">
                <Grid item>
                    <Button
                        variant="outlined"
                        className="m-2"
                        onClick={() => approvalStore.openSendApprovalDialog()}
                        disabled={!canEdit}
                    >
                        Finish review
                    </Button>
                </Grid>
                <Grid item>
                    {ApprovalStore.getApprovalStatusName(a?.current_status.status)}
                </Grid>
            </Grid>
        </Grid>

        <TaxonomyApprovalChanges/>

    </KoiFacade>
})
