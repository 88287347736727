import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {SynergyCommonSupplierReviewSubTable} from "./SynergyCommonSupplierReviewSubTable";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {
    CommonSupplierReviewGroupRowState,
    getSpend
} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {SYN_COMMON_SUPPLIER_COLUMNS} from "./columns";
import {useStores} from "../../../stores";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {GoogleLink} from "../../../components/_tiny/GoogleLink";
import {getCollapseAnimationTiming} from "../../supplier-normalization/pages/SupplierNormalization.utils";

type Props = {
    row: CommonSupplierReviewGroupRowState,
    forceOpen?: boolean
};
export const SynergyCommonSupplierReviewMainRow: React.FC<Props> = observer(({row, forceOpen}) => {
    const {synergyStore} = useStores();
    const review = synergyStore.commonSuppliersDelegate.review;
    const [open, setOpen] = React.useState(false);
    const nSubRows = row.subRows?.length;

    useEffect(() => {
        if (!open && review.allOpen === true)
            setOpen(true);
        if (open && review.allOpen === false)
            setOpen(false);
    }, [open, review.allOpen]);

    let i = 0;
    return <>
        <TableRow className="main-row">
            <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                        review.setAllOpen(null);
                        setOpen(!open);
                    }}
                    disabled={row.subRows === null}>
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
                <GoogleLink type="parent_supplier" objs={[row]}>
                    {row.cs_name}
                </GoogleLink>
            </MithraTableCell>
            <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
                <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                    <CurrencyComponent v={getSpend(row)} abbreviation={abbreviation}/>
                }</CurrencyAbbreviationContext.Consumer>
            </MithraTableCell>
            <MithraTableCell c={SYN_COMMON_SUPPLIER_COLUMNS[i++]}>
                {row.cs_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            {/*{row.s_total_bus.map(l => l === null ? 'None' : l).join(', ')}*/}
        </TableRow>
        <TableRow>
            <TableCell
                className="cell-sub-table"
                colSpan={SYN_COMMON_SUPPLIER_COLUMNS.length}
                style={{paddingLeft: SYN_COMMON_SUPPLIER_COLUMNS[0].width}}>
                {nSubRows !== undefined && nSubRows > 0 &&
                    <Collapse in={open || forceOpen} timeout={getCollapseAnimationTiming(row.subRows)} unmountOnExit>
                        <SynergyCommonSupplierReviewSubTable data={row}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
