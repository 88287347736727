import {Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GenericModel} from "../../../components/modal/GenericModal";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

// const SUPPLIER_OPPORTUNITY_TYPES = [
//     {label: '', value: ''},
// ]

type Props = {
    isOpen: boolean
    setOpen: (open: boolean) => void
}
export const SynergyCategoryOpportunityModal: React.FC<Props> = observer(({isOpen, setOpen}) => {
        const {cmpStore, opportunityStore} = useStores();
        const categoryCompareData = cmpStore.cmpCat.categoryCompareData;

        let opportunityId, opportunitySize;
        const isConnectionCreated = Boolean(cmpStore.cmpCat.selectedCreatedConnection);
        const c = cmpStore.cmpCat.selectedConnection;
        if (c) opportunityId = 'synergy-category-' + c.leftId + '-' + c.rightId;
        if (categoryCompareData) opportunitySize = categoryCompareData.master_spend_overlap + categoryCompareData.compare_spend_overlap;
        let masterL1 = '';
        let cmpL1 = '';
        if (cmpStore.cmpCat.selectedMasterLevel && cmpStore.cmpCat.selectedCompareLevel) {
            masterL1 = cmpStore.cmpCat.selectedMasterLevel[0];
            cmpL1 = cmpStore.cmpCat.selectedCompareLevel[0];
        }

        const [title, setTitle] = useState<string>('');

        useEffect(() => {
            setTitle('Merge ' + masterL1 + ' and ' + cmpL1);
        }, [masterL1, cmpL1])

        return <GenericModel
            title="Create Opportunity"
            saveTitle="Create"
            isOpen={isOpen}
            onSave={() => {
                if (!isConnectionCreated) {
                    cmpStore.cmpCat.onClickCreateConnection()
                }
                opportunityStore.createOpportunity({
                    id: opportunityId,
                    title,
                    data: {
                        type: 'any',
                        bodyValue: opportunitySize,
                    },
                    checked: false,
                });
                setOpen(false);
            }}
            onCancel={() => setOpen(false)}
            className="category-synergy-modal"
            titleId="category-synergy-modal-title"
        >
            <Grid container spacing={4}>

                <Grid item xs={6}>
                    <Typography className="value-title">Master category</Typography>
                    <Typography className="value">{masterL1}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className="value-title">Compare category</Typography>
                    <Typography className="value">{cmpL1}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className="value-title">Combined size</Typography>
                    <Typography className="value">
                        <CurrencyComponent v={opportunitySize}/>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Opportunity name"
                        variant="outlined"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </Grid>

            </Grid>
        </GenericModel>
    }
)
