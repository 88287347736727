import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Typography} from "@mui/material";
import {ApprovalDialog} from "../ApprovalDialog";
import {ReviewChoice} from "../../../services/classes/AiClasses";
import {useHistory} from "react-router";
import {MithraHistoryState} from "../../../routing/routes";

export const CategorizationApprovalAcceptDialog: React.FC = observer(() => {
    const history = useHistory<MithraHistoryState>();
    const {approvalStore} = useStores();

    const stats = approvalStore.cat.reviewFeedback.stats.result;
    const error = approvalStore.approvalLoader.error
        || (stats?.null && stats?.null > 0 && `${stats?.null} parts are not reviewed yet...`)
        || '';
    const nAccepted = stats?.[ReviewChoice.ACCEPT];
    const nRejected = stats?.[ReviewChoice.REJECT];
    return <ApprovalDialog
        className="approval-dialog"
        title="Do you want to apply the changes?"
        saveTitle={'Apply'}
        onSave={() => approvalStore.applyApproval(history)}
        canSave={!approvalStore.approvalLoader.busy}
        onClose={() => approvalStore.clickCancelApprovalDialog()}
        error={error}
        loading={approvalStore.approvalLoader.busy}
    >
        <Typography variant="body2" component="p">
            Sending {stats === undefined ? '.' : (nAccepted || 0)} parts as accepted<br/>
            Sending {stats === undefined ? '.' : (nRejected || 0)} parts as rejected<br/>
        </Typography>
        <Typography variant="body1" component="p">
            <br/>
            Just a heads up!
            Depending on the size of your data set, it might take a bit of time ⏰ to apply all the changes.
            <br/>
            Sit back, relax and grab a coffee ☕ while we take care of the rest.
        </Typography>
    </ApprovalDialog>
})
