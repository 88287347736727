import axios, {AxiosInstance} from "axios";
import {environment} from "../env";

export function buildAxiosInstance(baseURL: string) {
    const http = axios.create({
        baseURL,
        headers: {
            "Content-type": "application/json"
        }
    });
    if (environment.apiDelay > 0) {
        http.interceptors.request.use(c => {
            console.debug(`Mithra request delay of ${environment.apiDelay}ms on ${c.url}`)
            return new Promise(r => setTimeout(() => r(c), environment.apiDelay));
        });
    }
    return http;
}


/**
 * @see https://stackoverflow.com/questions/35855781/having-services-in-react-application
 */
export abstract class ApiService {
    constructor(public http: AxiosInstance) {
    }
}
