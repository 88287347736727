import {styled} from '@mui/material/styles';
import {Autocomplete, Box, TextField} from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {PartCategorizationComponent} from "../../components/categorization/PartCategorizationComponent";
import {EditorModalComponent} from "./EditorModalComponent";
import {Category} from "../../stores/TaxonomyEditorStore";

const PREFIX = 'MoveCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(EditorModalComponent)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    // option: {
    //     fontSize: 15,
    //     '& > span': {
    //         marginRight: 10,
    //         fontSize: 18,
    //     },
    // },
    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const MoveCategoryModal: React.FC = observer(() => {


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);
    const open = true;
    const handleSearchClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(undefined);
    }

    const id = open ? 'taxonomy-editor-existing-category-selection' : undefined;
    const {taxonomyEditorStore} = useStores();

    return (
        <StyledEditorModalComponent
            titleId={"taxonomy-editor-modal-title"}
            className="move-model-component"
            title="Move categories"
            canSave={taxonomyEditorStore.canMoveCategories}
            onSave={() => taxonomyEditorStore.saveMoveCategory()}
            onClose={() => taxonomyEditorStore.setMoveCategoryMode(false)}
        >
            {/*<div>*/}
            {/*    Selected category*/}
            {/*    {taxonomyEditorStore.lastSelected &&*/}
            {/*        <PartCategorizationComponent cats={taxonomyEditorStore.lastSelected.ancestors().reverse().map(a => a.data.label)}/>*/}
            {/*    }*/}
            {/*</div>*/}

            <Autocomplete
                id={id}
                // style={{width: 800}}
                options={taxonomyEditorStore.taxonomyParentOptions}
                className={classes.popoverContent}
                // classes={{
                //     option: classes.option,
                // }}
                // filterOptions={filterOptions}
                autoHighlight
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.node_id === value.node_id}
                renderOption={(props, option, {selected}) =>
                    <li {...props}>
                        {/* FIXME: When moving a category we show the root as uncategorized: 1/2h */}
                        <Box component="div">
                            <PartCategorizationComponent cats={option.values}/>
                        </Box>
                    </li>
                }
                onChange={(event: any, newValue: any | null) => {
                    if (newValue) {
                        const category = newValue as Category
                        taxonomyEditorStore.setMoveCategoryDestination(category)
                    }
                    handleClose()
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Select the L1 to to move this category to"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            tabIndex: 1,
                            // autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                }
            />
        </StyledEditorModalComponent>
    );
})
