import './DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Grid, TextField} from "@mui/material";
import {DataIngestionStepper} from "./DataIngestionStepper";
import FileUploadSingle from "./UploadButton";

type Props = {
    viewer?: boolean
}
export const DataUploadPage: React.FC<Props> = observer(() => {
    const {dataIngestionStore, p} = useStores();

    useEffect(() => {
        dataIngestionStore.navigateToPage('dataset_upload')
    }, [dataIngestionStore, p])

    return <div className="data-ingestion data-mapping-table">
        <DataIngestionStepper defaultPage={"dataset_upload"}></DataIngestionStepper>

        <Grid container justifyContent="center" className="data-ingestion-page-content">
            <Grid item xs={10}>

                <div className="dataset-upload">

                    {dataIngestionStore.errorsToShow.length !== 0 ? dataIngestionStore.errorsToShow.map((msg, i) => {
                        return <Alert key={i} severity="error">{JSON.stringify(msg)}</Alert>
                    }) : null}

                    <Grid container justifyContent="center" style={{marginTop: '3em'}}>
                        <Grid xs={7} item>
                            <TextField fullWidth id="outlined-basic" label="Type the name of the new dataset here"
                                       variant="outlined" value={dataIngestionStore.datasetName}
                                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                           dataIngestionStore.datasetName = event.target.value;
                                       }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{marginTop: '3em'}} justifyContent="center">
                        <Grid item>
                            <FileUploadSingle/>
                        </Grid>
                    </Grid>
                </div>


            </Grid>
        </Grid>
    </div>
});
