import {Grid, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {GenericModel} from "../../../components/modal/GenericModal";
import {useStores} from "../../../stores";
import {CommonSupplierData} from "../hardcoded/hardcoded_dpw_types";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

// const SUPPLIER_OPPORTUNITY_TYPES = [
//     {label: '', value: ''},
// ]

type Props = {
    isOpen: boolean
    setOpen: (open: boolean) => void
    data: CommonSupplierData
}
export const SynergySupplierOpportunityModal: React.FC<Props> = ({isOpen, setOpen, data}) => {
    const {opportunityStore} = useStores();

    const opportunityId = 'synergy-supplier-' + data.name;
    const combinedSpend = data.spend_master_ds + data.spend_cmp_ds;
    // const partData = data.parts_cmp_ds.concat(data.parts_master_ds);
    // partData.sort((a, b) => b.spend - a.spend);

    const [title, setTitle] = useState<string>('Consolidation of ' + data.name);

    const onCreateOpportunity = () => {
        opportunityStore.createOpportunity({
            id: opportunityId,
            title,
            checked: false,
            data: {
                type: 'any',
                bodyValue: combinedSpend,
            }
        })
    }

    return <GenericModel
        title="Create Opportunity"
        saveTitle="Create"
        // paperProps={{className: 'supplier-opportunity-create-modal'}}
        isOpen={isOpen}
        onSave={() => {
            onCreateOpportunity()
            setOpen(false);
        }}
        onCancel={() => setOpen(false)}
        className="supplier-opportunity-create-modal"
        titleId="supplier-opportunity-create-title"
    >
        <Grid container spacing={4}>

            <Grid item xs={6}>
                <Typography className="value-title">Supplier</Typography>
                <Typography className="value">{data.name}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="value-title">Supplier Type</Typography>
                <Typography className="value">{data.type}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className="value-title">Categories</Typography>
                <Typography className="value">{data.l1_categories}</Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography className="value-title">Total opportunity</Typography>
                <Typography className="value"><CurrencyComponent v={combinedSpend}/></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography className="value-title">Master dataset</Typography>
                <Typography className="value"><CurrencyComponent v={data.spend_master_ds}/></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography className="value-title">Compare dataset</Typography>
                <Typography className="value"><CurrencyComponent v={data.spend_cmp_ds}/></Typography>
            </Grid>

            <Grid item xs={9}>
                <TextField
                    fullWidth
                    label="Opportunity name"
                    variant="outlined"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
            </Grid>

        </Grid>
    </GenericModel>
}
