import {GenericModel} from "../../../components/modal/GenericModal";
import React from "react";

type Props = {
    isOpen: boolean
    setOpen: (open: boolean) => void
}
export const SynergySupplierFilterModal: React.FC<Props> = ({isOpen, setOpen}) => {
    return <GenericModel
        title="Filter"
        saveTitle="Apply"
        isOpen={isOpen}
        onSave={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        titleId="suppler-synergy-filter"
    >
        <p>TODO</p>
        {/*<FormControl variant="standard">*/}
        {/*    <InputLabel id="demo-mutiple-name-label">Name</InputLabel>*/}
        {/*    <Select*/}
        {/*        variant="standard"*/}
        {/*        labelId="demo-mutiple-name-label"*/}
        {/*        id="demo-mutiple-name"*/}
        {/*        multiple*/}
        {/*        value={"Strategic"}*/}
        {/*        // onChange={handleChange}*/}
        {/*    >*/}
        {/*        <MenuItem value="Strategic">*/}
        {/*            Strategic*/}
        {/*        </MenuItem>*/}
        {/*        <MenuItem value="Transactional">*/}
        {/*            Transactional*/}
        {/*        </MenuItem>*/}
        {/*    </Select>*/}
        {/*</FormControl>*/}
    </GenericModel>
}
