import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {LinearProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {range} from "d3";
import {getHardcodedLabel} from "../../../components/visualization/collapsible-indent-tree/labelmapping";
import {C} from "../../../configurations/active-configuration";
import {hackGetTaxonomyMapperStore} from "../../../stores/hack";


export const TaxonomyMapperUnmappedReviewTable: React.FC = observer(() => {
    let {rootAppStore, taxonomyMapperStore} = useStores();
    if (C.package === 'merge_x') {
        taxonomyMapperStore = hackGetTaxonomyMapperStore(rootAppStore.app);
    }

    const isLoading = taxonomyMapperStore.mapper._catMapRequestManager.busy;
    const tableClass = 'mithra-table x-design' + (isLoading ? ' loading' : '');

    const unMatchData = taxonomyMapperStore.mapper.unMatchedCategories;

    const LEFT_TAXONOMY_SIZE = 4;

    const LEFT_COLS = range(LEFT_TAXONOMY_SIZE).map(i => `source_l${i + 1}` as 'source_l1');

    return <Table className={'taxonomy-mapper-review-unmatched-table ' + tableClass} cellPadding="0">
        <TableHead>
            <TableRow>
                {LEFT_COLS.map((col, i) =>
                    <TableCell key={col} className="col-category col-left">
                        l{i + 1}
                    </TableCell>
                )}
            </TableRow>
            <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                <td colSpan={LEFT_COLS.length}>
                    {isLoading && <LinearProgress variant="indeterminate"/>}
                </td>
            </tr>
        </TableHead>
        <TableBody className="taxonomy-mapper-review-table-body">
            {unMatchData ?
                <>{unMatchData.map(node => {

                    const cats = node.ancestors()
                        .map(n => n.data.label)
                        .reverse()
                        .filter((_, i) => i > 0);
                    cats.push(...range(LEFT_TAXONOMY_SIZE - cats.length).map(() => ''));

                    return <TableRow key={node.id}>
                        {/*<TableCell className="col-category col-left">*/}
                        {/*    {cats.length}*/}
                        {/*</TableCell>*/}
                        {cats.map((cat, i) => <TableCell
                            key={i + '_' + cat}
                            className="col-category col-left">
                            {getHardcodedLabel(cat)}
                        </TableCell>)}
                        {/*<TableCell className="col-filler"/>*/}
                        {/*{RIGHT_COLS.map(col => <TableCell key={col} className="col-category col-right">*/}
                        {/*    {row[col]}*/}
                        {/*</TableCell>)}*/}
                    </TableRow>;
                })}{unMatchData.length === 0 &&
                    <TableRow className="no-data">
                        <TableCell colSpan={6}>
                            No data
                        </TableCell>
                    </TableRow>
                }</>
                : <TableRow className="no-data-yet"/>
            }
        </TableBody>
    </Table>
});
