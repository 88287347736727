import React from "react";
import {Grid, Typography} from "@mui/material";
import {useHistory} from "react-router";
import Button from "@mui/material/Button";
import {routes} from "./routes";
import './NotFound.scss';

export const NotFound: React.FC<{ msg?: string }> = ({msg}) => {
    const history = useHistory();

    return <div className="not-found-page">
        <Grid container justifyContent="center">
            <Grid item>
                <Typography variant="h3" component="h1">
                    Page not found
                </Typography>
                {msg && <Typography variant="body1" component="p">{msg}</Typography>}
                <Button variant="contained" color="primary" onClick={() => history.push(routes.home)}>
                    Go back to the homepage
                </Button>
            </Grid>
        </Grid>
    </div>
};
