import React from "react";
import {observer} from "mobx-react-lite";
import {Alert, CircularProgress, Grid} from "@mui/material";
import {useStores} from "../../../stores";
import {environment} from "../../../env";

export const SupplierNormalizationApplyingResultsStep: React.FC = observer(() => {
    const {supplierNormalizationStore} = useStores();
    return <section className="page-item step applying-results-step">
        <Grid container justifyContent="center">
            <Grid item>
                {supplierNormalizationStore.state === 'applying'
                    ? <Alert
                        severity="info"
                        icon={false}
                        // icon={<HourglassEmpty fontSize="inherit" />}
                        onClick={() => environment.isTest && supplierNormalizationStore.goToState('done')}>
                        <span>
                            <CircularProgress size={15}/>
                        </span>
                        Applying results, please wait
                    </Alert>
                    : <Alert severity="success">
                        Results applied successfully
                    </Alert>
                }
            </Grid>
        </Grid>

        {/*<Grid container justifyContent="space-evenly">*/}
        {/*    <Grid item xs={3} className="text-center">*/}
        {/*        <Button variant="contained" color="primary"*/}
        {/*                onClick={() => supplierNormalizationStore.gotoRunModelState()}>*/}
        {/*            Start model*/}
        {/*        </Button>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}
    </section>
})
