// const HARDCODED_LABEL_ANNOMIZATION = {
//     'Direct': 'Direct',
//     'PACKAGING': 'BOX',
//     'BOX': 'PACKAGING MATERIALS',
//     'BOX - METAL': 'PACKAGING TRAYS',
//     'BOX - PLASTIC': 'COMBOS',
//     'BOX - WOOD': 'ADDITIVES',
//     'OTHER PACKAGING': 'PKG PEANUTS, BUBBLE WRAP, CUSHIONING, BLISTER, AIR FILLED',
//     'ADHESIVES AND SEALANTS': 'DUNNAGE, STABILIZER',
//     'COMBOS': 'WOODEN CRATES',
//     'DUNNAGE, STABILIZER': 'PACKAGING LABELS',
//     'MEASURING DEVICE': 'ADHESIVES AND SEALANTS',
//     'PACKAGING MATERIALS': 'OTHER PACKAGING',
//     'PACKAGING TRAYS': 'CHEMICALS',
//     'PACKING TAPE': 'PLYWOOD',
//     'PKG PEANUTS, BUBBLE WRAP, CUSHIONING , BLISTER , AIR FILLED': 'EXTENDERS',
//     'PLYWOOD': 'TANKS AND CYLINDERS',
//     'RADIO FREQUENCY IDENTIFICATION TAGS': 'RADIO FREQUENCY IDENTIFICATION TAGS',
//     'RETURNABLE / REFILLABLE CONTAINER': 'WAXES',
//     'TANKS AND CYLINDERS': 'ADDITIVES – OTHER',
//     'WOODEN CRATES': 'NEUTRALISATION AGENTS',
//     'PACKAGING LABELS': 'SURFACTANTS',
//     'Chemicals': 'FIRE PROTECTION AGENTS',
//     'Additives': 'CATALYSTS & INITIATORS',
//     'Additives – Other': 'PLASTICIZERS',
//     'Adhesion Promotors': 'DRIERS & ANTISKINNING AGENTS',
//     'Biocides': 'LIGHT & HEAT STABILIZERS',
//     'Caseins & Starches': 'ACRYLIC RESINS – SOLID',
//     'Catalysts & Initiators': 'DEFOAMERS & ANTIFOAMS',
//     'Defoamers & Antifoams': 'CASEINS & STARCHES',
//     'Degassing Agents': 'BIOSIDES',
//     'Driers & Antiskinning Agents': 'ADHESION PROMOTORS',
//     'Extenders': 'DEGASSING AGENTS',
//     'Fire Protection Agents': 'PACKING TAPE',
//     'Light & Heat Stabilizers': 'PLYWOOD',
//     'Neutralisation Agents': 'MEASURING DEVICE',
//     'Plasticizers': 'RETURNABLE / REFILLABLE CONTAINER',
//     'Rheology & Thixotropic Agents': 'PACKAGING',
//     'Surfactants': 'BOX - WOOD',
//     'Waxes': 'BOX - METAL',
//     'Commodity Resins': 'BOX - PLASTIC',
//     'Acrylic Resins – Solid': 'EPOXY RESINS – LIQUID',
//     'Commodity Resins Precursors': 'EPOXY RESINS – SOLID',
//     'Epoxy Resins – Liquid': 'COMMODITY RESINS PRECURSORS',
//     'Epoxy Resins – Solid': 'RHEOLOGY & THIXOTROPIC AGENTS',
//
//     '11 Tax & Duties': 'Taxes',
//     '21 Case Packer': 'Packer',
//     '21 Product Coding': 'Product',
//     '22 HVAC': '22',
//     '22 Warehousing Equipment': 'Warehousing',
//     '23 Bldg Construction-Civil': 'Construction',
//     '23 Bldg Construction-Electrical': 'Electrical',
//     '23 Bldg Construction-Mechanical': 'Mechanical',
//     '23 Bldg Infrastructure Equip.': 'Equipement',
//     '24 MRO Services': 'MRO',
//     '24 MRO Supplies': 'MRO',
//     '24 Workshop Equipment&Supplies': 'Workshop',
//     '26 Beverages machines for sales and spare parts': 'Beverages',
//     '3 Boutiques': 'Boutiques',
//     '3 Refrigerators': 'Refrigerators',
//     '30 Design': 'Design',
//     '30 Event Marketing': 'Marketing',
//     '30 Field activation': 'Activation',
//     '30 POSM': 'Plastics',
//     '30 Print': 'Printing',
//     '30 Promotional items': 'Items',
//     '30 Promotional MKT': 'Make',
//     '30 Public Relations': 'Public',
//     '30 Shopper MKT': 'Shops',
//     '30 Total Trade Spend – TTS': 'Spend',
//     '31 Advertising Production - Digital': 'Advertising',
//     '31 Advertising Production - Traditional / Offline': 'Advertising',
//     '31 Creative Agency Remuneration': 'Creative',
//     '31 Digitial Agency Remuneration': 'Digitial',
//     '32 Custom Research': 'Research',
//     '32 Syndicated': 'Synergies',
//     '33 Media Agency Remuneration': 'Agency',
//     '33 Media Audit': 'Audio',
//     '33 Media Buying': 'Purchase',
//     '33 Media Technical Online & Offline Cost': 'Cost',
//     '34 Bus/ Shuttles/ Special Vehicles': 'Vehicles',
//     '34 Corporate Credit Card Services': 'Corporate',
//     '34 Delivery/Service Large Van': 'Cars and Vans',
//     '34 Leased - Company Car': 'Cars',
//     '34 Meetings & Events': 'Events',
//     '34 Purchased/Owned - Company Car': 'Company Car',
//     '34 Travel': 'Travel',
//     '35 Contingent Workforce': 'Workforce',
//     '35 Management Consulting Services': 'Management',
//     '37 Facilities Management': 'Facilities',
//     '37 Real Estate Services': 'Real',
//     '37 Workplace Design': 'Workplace',
//     '38 Energy': 'Energy',
//     '38 Utilities': 'Utilities',
//     '6 Business/Prof. Associations': 'Business',
//     '6 Corporate Donations & Gifts': 'Corporate',
//     '6 OA & HR Other Admin/HR Needs': 'HR',
//     '6 Office Equipment': 'Office',
//     '6 Office Services': 'Servie',
//     '6 Office Supplies': 'Office',
//     '6 Publications': 'Publications',
//     '6 Recruitment': 'Office',
//     '6 Relocation Services': 'Relocation Services',
//     '6 Training & Education': 'Training & Education',
//     '6 Union Relations': 'Union Relations',
//     '6 Welfare & Benefits': 'Welfare & Benefits',
//     '7 Banking & Investment Service': 'Banking & Investment Service',
//     '7 Corp.Public Relation & Serv.': 'Corp.Public Relation & Serv.',
//     '7 Credit & P-Card Services': 'Credit & P-Card Services',
//     '7 Financial Services (Audit)': 'Financial Services (Audit)',
//     '7 Legal Services': 'Legal Services',
//     '7 Life Insurance': 'Life Insurance',
//     '7 Medical/Health insurance': 'Medical',
//     '7 Non-Life Insurance': 'Insurance',
//     '7 Other Professional Services': 'Other',
//     '7 Retirement services': 'Services',
//     '7 Scientific Services': 'Services',
//     '8 Co-Manufacturing fee Human': 'Manufacturing',
//     '9 Air Frght not fast courier': 'Courier',
//     '9 Freight Forwarding': 'Forwarding',
//     '9 Hired pallets / lids': 'Hired',
//     '9 Inland water frght': 'Water',
//     '9 Intermodal Freight road/rail': 'Freight',
//     '9 Logistics serv.': 'Logistics',
//     '9 Ocean Frght deep/short sea': 'Freight',
//     '9 Rail Freight': 'Freight',
//     '9 Road Freight': 'Freight',
//     '9 Warehouse services': 'Warehouse',
//     '36 Analytics': 'Analytics',
//     '36 BusinessProcServ': 'Server',
//     '36 Finance & Legal': 'Finance',
//     '36 Human Resources': 'Resources',
//     '36 IT Platforms': 'IT',
//     '36 MarktgSalese&Bus': 'Marketing',
//     '36 Office of the CIO': 'Office',
//     '36 R&D, Innovation': 'Innovation',
//     '36 Security Comp': 'Security',
//     '36 SupplyChain&Proc.': 'Supply',
//     '36 Tech.&Prod.': 'Technology',
//     '36 Workforce 360': 'Work',
//     '11 Income tax': 'Income',
//     '11 Land tax': 'Property',
//     '11 Other Taxation': 'Other Tax',
//     '11 Payroll tax': 'Payroll',
//     '11 Property tax': 'Real Tax',
//     '11 Sales tax': 'More Tax',
//     '11 Social security tax': 'VAT tax',
//     '11 Value added tax (VAT)': 'Value added tax (VAT)',
//     '21 Tray/Case Erector': 'Trays',
//     '21 Wrap-Around': 'Wrapping',
//     '21 Coding Equipment': 'Coding',
//     '21 Consumables': 'Consumables',
//     '21 Lease/Paypercode': 'Cat',
//     '22 Air Filters': 'Air',
//     '11 T&D Tax & Duties': 'Taxes',
//     '21 Filling & Packing Equipment': 'Packing Equipment',
//
//     '20 Agricultural Supplies & Services': 'Agricultural Supplies',
//     '21 Customized Solutions': 'Solutions',
//     'Packing Equipment': 'Equipment',
//     '21 Turnkey Solutions': 'Solutions',
//     '22 Generic Equipment': 'Generic',
//     '23 Land, Building & Structures': 'Building',
//     '24 MRO Supplies and Services': 'Service',
//     '25 Processing Equipment': 'Equipment',
//     '26 Beverages Systems': 'Systems',
//     '3 Trade Assets (Partially Discontinued)': 'Trades',
//     '34 Mobility': 'Cars',
//     '35 External Workforce': 'Internal',
//     '37 Workplace Solution': 'Solutions',
//     '38 Energy, Utilities & Environment': 'Energy',
//     '39 Marketing': 'Marketing',
//     '6 Administration & HR Services (Partially discontinued)': 'HR',
//     '7 Other Professional Services (Partially discontinued)': 'Other',
//     '8 Contract Manufacturing': 'Manufacturing',
//     '9 Logistics': 'Logistics',
//     '99 R&D / PTC site use only': 'Sites',
//     'S36 - IS/IT & BPS': 'IT',
//     'Taxes': 'Taxes',
//     '20 Agricultural Equipment': 'Equipment',
//     '20 Animals, Feed & Healthcare': 'Healthcare',
//     '20 Farming Supplies': 'Supplies',
//     '21 Automatic Feeding System (customized)': 'Systems',
//     '21 Packing/Unpacking (customized)': 'Packaging',
//
//     '3 Trade Assets': 'Trading',
//     '30 Activation': 'Actions',
//     '31 Content': 'Text',
//     '32 Insight': 'Spending',
//     '33 Media': 'Clockworks',
//
//     '3 Other Commercial Refriger.': 'Other',
//     '3 Refriger. Maintenance/Serv.': 'Maintenance',
//     '3 Upright Refrigerators': 'Upright',
//     '3 Refurbishing Works': 'Works',
//     '3 Lighting': 'Lighting',
//     '3 Technical Equipment': 'Equipment',
//     '3 Repair': 'Repair',
//     '3 Safety': 'Safety',
//     '3 Maintenance and Cleaning': 'Cleaning',
//     '6 Business associations': 'Business',
//     '6 Professional associations': 'Professional',
//     '6 Other Work related organiz.': 'Other',
// }

/**
 * Used for Berlin demo
 * @param key
 */
export function getHardcodedLabel(key: string | any) {
    // key = String(key);
    // if (HARDCODED_LABEL_ANNOMIZATION[key]) {
    //     return HARDCODED_LABEL_ANNOMIZATION[key];
    // }
    return key;
}
