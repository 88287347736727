import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Typography
} from "@mui/material";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import './OpportunitiesTrello.scss';
import {useStores} from "../../stores";
import {OpportunityItem} from "../../stores/OpportunityStore";
import {observer} from "mobx-react-lite";
import {ArrowBack, ArrowForward, DeleteOutline} from "@mui/icons-material";
import {SupplierConsolidationOpportunityModal} from "./SupplierConsolidationOpportunityModal";

export const OpportunitiesTrello: React.FC = observer(() => {
    const {opportunityStore} = useStores();

    useEffect(() => {
        const s = opportunityStore.$init()
        return () => s.unsubscribe();
    })
    const [isOpportunityModelOpen, setOpportunityModalOpen] = useState(false);

    return <KoiFacade
        title="Opportunities"
        back={false}
        className="opportunities-trello-page">
        <Grid container justifyContent="space-evenly" className="page-item">
            <SupplierConsolidationOpportunityModal isOpen={isOpportunityModelOpen} setOpen={setOpportunityModalOpen}/>
            <Grid item>
                <OpportunitiesTrelloPane
                    title="Identified"
                    items={opportunityStore.identified}
                    setOpportunityModalOpen={setOpportunityModalOpen}
                />
            </Grid>
            <Grid item className="button-pane">
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => opportunityStore.move('identified', 'qualified')}
                        disabled={!opportunityStore.hasCheckedIdentified}
                        aria-label="move selected right"
                    >
                        <ArrowForward/>
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => opportunityStore.move('qualified', 'identified')}
                        disabled={!opportunityStore.hasCheckedQualified}
                        aria-label="move selected left"
                    >
                        <ArrowBack/>
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => opportunityStore.delete('identified')}
                        disabled={!opportunityStore.hasCheckedIdentified}
                        aria-label="delete selected"
                    >
                        <DeleteOutline/>
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <OpportunitiesTrelloPane
                    title="Qualified"
                    items={opportunityStore.qualified}
                    setOpportunityModalOpen={setOpportunityModalOpen}
                />
            </Grid>
            <Grid item className="button-pane">
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => opportunityStore.move('qualified', 'realized')}
                        disabled={!opportunityStore.hasCheckedQualified}
                        aria-label="move selected right"
                    >
                        <ArrowForward/>
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => opportunityStore.move('realized', 'qualified')}
                        disabled={!opportunityStore.hasCheckedRealized}
                        aria-label="move selected left"
                    >
                        <ArrowBack/>
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <OpportunitiesTrelloPane
                    title="Realized"
                    items={opportunityStore.realized}
                    setOpportunityModalOpen={setOpportunityModalOpen}
                />
            </Grid>
        </Grid>
    </KoiFacade>
})

type Props = {
    title: string
    items: OpportunityItem[]
    setOpportunityModalOpen: (open: boolean) => void,
}
const OpportunitiesTrelloPane: React.FC<Props> = observer(({title, items, setOpportunityModalOpen}) => {
    const {opportunityStore} = useStores();
    return <section className="opportunities-trello-pane">
        <Paper className="opportunities-trello-pane-container" elevation={4}>
            <List dense subheader={
                <ListSubheader>
                    <Paper className="page-header-item">
                        <Typography variant="h5" className="pane-header">
                            {title}
                        </Typography>
                    </Paper>
                </ListSubheader>
            }>
                {items.map(val => {
                    const labelId = `transfer-list-item-${val.id}-label`;
                    return (
                        <ListItem key={val.id} role="listitem" button onClick={() => opportunityStore.toggle(val)}
                                  className="trello-list-item">
                            <ListItemIcon>
                                <Checkbox
                                    checked={val.checked}
                                    // tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={
                                <Card elevation={1}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className="trello-header-line">
                                            {val.title}
                                        </Typography>
                                        <Typography variant="body1" className="trello-value-line">
                                            {val.data.type === 'any' &&
                                                <CurrencyComponent v={val.data.bodyValue}/>}
                                            {val.data.type === 'supplier_consolidation' &&
                                                <CurrencyComponent v={val.data.opportunitySize}/>}
                                        </Typography>
                                        <Button onClick={() => {
                                            opportunityStore.setEditOpportunity(val);
                                            setOpportunityModalOpen(true);
                                        }}>Open</Button>
                                    </CardContent>
                                </Card>
                            }/>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
        {/*<Grid container direction="column" spacing={4}>*/}
        {/*    {items.map(i =>*/}
        {/*        <Grid item key={i.id}>*/}
        {/*            <Card elevation={4}>*/}
        {/*                <CardContent>*/}
        {/*                    <Typography variant="subtitle1">*/}
        {/*                        {i.title}*/}
        {/*                    </Typography>*/}
        {/*                    <Typography variant="body1">*/}
        {/*                        <CurrencyComponent v={i.bodyValue}/>*/}
        {/*                    </Typography>*/}
        {/*                </CardContent>*/}
        {/*            </Card>*/}
        {/*        </Grid>*/}
        {/*    )}*/}
        {/*</Grid>*/}
    </section>
});
