import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import {styled} from '@mui/material/styles';
import {ExpandMore} from "@mui/icons-material";
import {CategoryChip} from "../../../components/category-chip/CategoryChip";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";

const PREFIX = 'TaxonomyApprovalChanges';

const classes = {
    root: `${PREFIX}-root`,
    heading: `${PREFIX}-heading`,
    preHeading: `${PREFIX}-preHeading`
};

const StyledGrid = styled(Grid)(({theme}) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.heading}`]: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },

    [`& .${classes.preHeading}`]: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '3em',
        flexShrink: 0,
        color: theme.palette.text.secondary,
    }
}));

export const TaxonomyApprovalChanges: React.FC = observer(() => {
    const {approvalStore} = useStores();

    const a = approvalStore.taxonomyApproval;
    const [expanded, setExpanded] = React.useState<string | false>(false);


    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (!a) return <></>;

    return (
        <StyledGrid container justifyContent="center" className="mt-4">
            <Grid item md={8} xs={12}>
                <Typography variant="h4">Change overview</Typography>

                <Accordion
                    expanded={expanded === 'created'}
                    onChange={handleChange('created')}
                    className="mt-4"
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="created-bh-content"
                        id="created-bh-header"
                    >
                        <Typography className={classes.preHeading}>{a.changelog.created.length}</Typography>
                        <Typography className={classes.heading}>Created categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {a.changelog.created.map(n => <ListItemText
                                key={n.node_id}
                                primary={<CategoryChip labels={n.labels}/>}
                                // secondary={n.node_id}
                            />)}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'merged'} onChange={handleChange('merged')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="merged-bh-content"
                        id="merged-bh-header"
                    >
                        <Typography className={classes.preHeading}>{a.changelog.merged.length}</Typography>
                        <Typography className={classes.heading}>Merged categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {a.changelog.merged.map(n =>
                                <ListItem
                                    key={n.node_id}>
                                    <div>
                                        <Typography>From:</Typography>
                                        <div>{n.sources.map(s =>
                                            <div key={s.node_id} className={"m-1"}>
                                                <CategoryChip labels={s.labels}/>
                                            </div>
                                        )}</div>
                                    </div>
                                    <div className="ml-2">
                                        <Typography>To:</Typography>
                                        <div><CategoryChip labels={n.labels}/></div>
                                    </div>
                                </ListItem>
                            )}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'moved'} onChange={handleChange('moved')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="moved-bh-content"
                        id="moved-bh-header"
                    >
                        <Typography className={classes.preHeading}>{a.changelog.moved.length}</Typography>
                        <Typography className={classes.heading}>Moved categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {a.changelog.moved.map(n =>
                                <ListItem key={n.node_id}>
                                    <div>
                                        <Typography>From:</Typography>
                                        <div><CategoryChip labels={n.old_labels}/></div>
                                    </div>
                                    <div className="ml-2">
                                        <Typography>To:</Typography>
                                        <div><CategoryChip labels={n.labels}/></div>
                                    </div>
                                </ListItem>
                            )}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'edited'} onChange={handleChange('edited')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="edited-bh-content"
                        id="edited-bh-header"
                    >
                        <Typography className={classes.preHeading}>{a.changelog.edited.length}</Typography>
                        <Typography className={classes.heading}>Edited categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {a.changelog.edited.map(n =>
                                <ListItem key={n.node_id}>
                                    <div>
                                        <Typography>Category:</Typography>
                                        <div><CategoryChip labels={n.labels}/></div>
                                    </div>
                                    <div className="ml-3">
                                        <Typography>Fields:</Typography>
                                        {n.changes.map(c => <div key={c.field} className="mb-1">
                                            <Typography component="span">Changed {c.field}</Typography>
                                            <Paper component="span" className="p-1 mr-2 ml-2" variant="outlined" square>
                                                {c.old_value}
                                            </Paper>
                                            <Typography component="span" className="">to</Typography>
                                            <Paper component="span" className="p-1 mr-2 ml-2" variant="outlined" square>
                                                {c.new_value}
                                            </Paper>
                                        </div>)}
                                    </div>
                                </ListItem>
                            )}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'deleted'} onChange={handleChange('deleted')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="deleted-bh-content"
                        id="deleted-bh-header"
                    >
                        <Typography className={classes.preHeading}>{a.changelog.deleted.length}</Typography>
                        <Typography className={classes.heading}>Deleted categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {a.changelog.deleted.map(n => <ListItemText
                                key={n.node_id}
                                primary={<CategoryChip labels={n.labels}/>}
                            />)}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </StyledGrid>
    );
})
