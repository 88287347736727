import './SynergySelectionPage.scss';
import React, {useEffect} from "react";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../../stores";
import {Button, Grid, Typography} from "@mui/material";
import {DataSetSelectComponent} from "../../manage-data/DataSetSelectComponent";
import {Bag} from "../../../services/classes/Bag";
import {forkJoin} from "rxjs";
import {generatePath, useHistory} from "react-router";
import {CmpDataBagRouteMatch, JobRouteMatch, routes} from "../../../routing/routes";
import {PlayCircleOutline} from "@mui/icons-material";
import {generateGotoDashboardPath, generateHomePath} from "../../../routing/routing.utils";

export const SynergySelectionPage: React.FC = () => {
    const history = useHistory();
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    const rawBagId1 = Number(routeMatch.params.id);
    const rawBagId2 = Number(routeMatch.params.id2);
    const {bagStore, cmpStore} = useStores();

    const [bags, setBags] = React.useState<Bag[]>();
    useEffect(() => {
        const sub = forkJoin([
            bagStore.getAllBags()
        ]).subscribe(([_bags]) => {
            setBags(_bags);
            if (!selectedBag1Id && !selectedBag2Id && _bags.length > 0) {
                const id1 = _bags[0].id;
                const id2 = _bags.length > 1 ? _bags[1].id : id1;
                setSelectedBag1Id(id1)
                setSelectedBag2Id(id2)
            }
        });
        return () => sub.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bagStore])


    const [selectedBag1Id, setSelectedBag1Id] = React.useState(isNaN(rawBagId1) ? undefined : rawBagId1);
    const [selectedBag2Id, setSelectedBag2Id] = React.useState(isNaN(rawBagId2) ? undefined : rawBagId2);
    const selectedBagIds = [selectedBag1Id, selectedBag2Id];

    const [error1, setError1] = React.useState('');
    const [error2, setError2] = React.useState('');

    const triggerError1 = () => setError1('Please select the master data');
    const triggerError2 = () => setError2('Please select a dataset to compare');
    const triggerErrors = [triggerError1, triggerError2];

    const handleSubmit = () => {
        if (selectedBag1Id && selectedBag2Id) {
            const masterBag = bags?.find(b => b.id === selectedBag1Id);
            const compareBag = bags?.find(b => b.id === selectedBag2Id);
            if (masterBag) {
                setError1('');
                cmpStore.setMasterBag(masterBag)
            } else triggerError1()
            if (compareBag) {
                setError2('');
                cmpStore.setCompareBag(compareBag)
            } else triggerError2()
            if (masterBag && compareBag) {
                history.push(generateGotoDashboardPath(routeMatch, {
                    id: String(selectedBag1Id),
                    id2: String(selectedBag2Id)
                }));
            }
        } else {
            if (!selectedBag1Id) triggerError1();
            if (!selectedBag2Id) triggerError2();
        }
    }
    const handleEdit = (n) => {
        n--;
        const selectedId = selectedBagIds[n];
        if (!selectedId) {
            triggerErrors[n]()
            return;
        }
        console.warn('Redirecting to original app, not implemented appEnv ...')
        // history.push(generateManage??Path(routeMatch, {id: selectedId}));
        history.push(generatePath(routes.job_manage, {id: selectedId}));
    }
    const handleView = (n) => {
        n--;
        const selectedId = selectedBagIds[n];
        if (!selectedId) {
            triggerErrors[n]()
            return;
        }
        history.push(generateHomePath<JobRouteMatch>(routeMatch, {id: String(selectedId)}));
    }

    return <main className="synergy-selection-page">
        <Grid container justifyContent="center" className="page-header">
            <Grid item>
                <Typography variant="h3" component="h1" className="title">
                    Select data set
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={4} justifyContent="center" alignItems="center" className="page-content">
            <Grid item lg={3} md={4} className="input-panel right">
                <Typography variant="h4" className="text-center">Master dataset</Typography>
                <DataSetSelectComponent
                    id="ds-1-"
                    label="Select dataset"
                    centered
                    error={error1}
                    bags={bags}
                    selectedBagId={selectedBag1Id || ''}
                    onSelectBag={b => {
                        setError1('');
                        setSelectedBag1Id(b.id)
                    }}/>
                <Grid container justifyContent="space-evenly">
                    <Grid item>
                        <Button variant="outlined" disabled={!selectedBag1Id}
                                onClick={() => handleEdit(1)}>Data</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" disabled={!selectedBag1Id}
                                onClick={() => handleView(1)}>View</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={12} className="submit-panel text-center">
                <Button
                    startIcon={<PlayCircleOutline/>}
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}>
                    Start
                </Button>
            </Grid>
            <Grid item lg={3} md={4} className="input-panel left">
                <Typography variant="h4" className="text-center">Synergy dataset</Typography>
                <DataSetSelectComponent
                    id="ds-2-"
                    label="Select dataset"
                    centered
                    error={error2}
                    bags={bags}
                    selectedBagId={selectedBag2Id || ''}
                    onSelectBag={b => {
                        setError2('');
                        setSelectedBag2Id(b.id)
                    }}/>
                <Grid container justifyContent="space-evenly">
                    <Grid item>
                        <Button variant="outlined" disabled={!selectedBag2Id}
                                onClick={() => handleEdit(2)}>Data</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" disabled={!selectedBag2Id}
                                onClick={() => handleView(2)}>View</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </main>
};
