import React from "react";
import {Typography} from "@mui/material";
import {AcceptButtons} from "./AcceptButtons";
import {SingleReviewState} from "../../stores/CategorizationReviewStore";

type Props = {
    onAccept?: () => void
    state: SingleReviewState
}
export const SingleReviewButtons: React.FC<Props> = ({onAccept, state}) => {
    const isAccept = state.btn === 'accepted';
    let extra: JSX.Element;
    switch (state.extra) {
        case "waiting_approval":
            extra = <Typography>Waiting approval</Typography>
            break;
        case "approved_accepted":
            extra = <Typography>Approved</Typography>
            break;
        case "approval_rejected":
            extra = <Typography>Rejected</Typography>
            break;
        case "approval_prev_rejected":
            extra = <Typography>Previously rejected</Typography>
            break;
        case "":
            extra = <></>;
            break;
    }
    return <>
        {state.btn !== 'hidden' &&
            <AcceptButtons
                isAccept={isAccept}
                onAccept={onAccept}
                byOther={state.reviewedByOther}
            />
        }
        {extra}
    </>
}
